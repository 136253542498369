import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Button, FormControl, TableCell, TableContainer, Paper, Table, TableHead, TableBody, TableRow, TextField, Grid, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import moment from 'moment';
import ConfirmDialog from '../ConfirmDialog';
import { formatMoney } from '../../utils';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 500
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 800
    },
    formControl: {
        margin: theme.spacing(1),
        width: '49%',
    },
    formControlFullWidth: {
        margin: theme.spacing(1, 0),
        width: '100%',
    },
    actionButton: {
        cursor: 'pointer'
    },
    deleteEntry: {
        cursor: 'pointer',
        marginTop: 13
    }
}));

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function BankReceipts({ selectedSociety }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const blankFormValue = {
        docNo: 0,
        date: moment().format('YYYY-MM-DD'),
        debits: [],
        credits: [],
        narration: ''
    };

    const [recordAdded, setRecordAdded] = useState(1);  // Record Added OR Record Edit
    const [errors, setErrors] = useState([]);
    const [mode, setMode] = useState('Create');
    const [model, setModel] = useState(Object.assign({}, blankFormValue));
    const [accounts, setAccounts] = useState([]);
    const [narrations, setNarrations] = useState([]);

    const [transactions, setTransactions] = useState([]);
    const [transactionsLimit, setTransactionsLimit] = useState(5);
    const [refreshTransactions, setRefreshTransactions] = useState(1);

    const [deleteModel, setDeleteModel] = React.useState();
    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

    const blankNewEntryValue = { account: { code: '', title: '' }, amount: '' };
    const [newDebitEntry, setNewDebitEntry] = useState(blankNewEntryValue);
    const [newCreditEntry, setNewCreditEntry] = useState(blankNewEntryValue);

    useEffect(() => {
        axios.get('/account/selectitems/all', { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setAccounts(response.data);
            });
    }, [selectedSociety]);

    useEffect(() => {
        axios.get('/narration/list', { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setNarrations(response.data);
            });
    }, [selectedSociety, recordAdded]);

    useEffect(() => {
        axios.get('/journalvoucher/list/desc/' + transactionsLimit, { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setTransactions(response.data);
            });
    }, [selectedSociety, recordAdded, transactionsLimit, refreshTransactions]);

    function loadMore() {
        setTransactionsLimit(transactionsLimit + 10);
    }

    function edit(row) {
        let newErrors = [];
        setErrors(newErrors);
        
        axios.get('/journalvoucher/' + row.docNo, { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setModel(response.data);
                setRecordAdded(recordAdded + 1);
                setMode('Edit');
                setTimeout(() => document.getElementById('debitaccount').focus(), 200);
            });
    }

    function ddelete(row) {
        setDeleteModel(row);
        setShowConfirmDelete(true);
    }

    function save() {
        // If you enter new value in freeSolo Autocomplete and don't press enter, the value is not added to the model
        // If you assign the value to the model on keyUp or any other event the filtering does not work correctly
        // Hence, the below workaround
        model.narration = document.getElementById('narration').value;
        
        let newErrors = [];
        if (!model.date) newErrors.push('Date is required');
        if (model.debits.length === 0) newErrors.push('At least one Debit entry is required');
        if (model.credits.length === 0) newErrors.push('At least one Credit entry is required');

        const debitTotal = model.debits.map(o => o.amount).reduce((a, b) => a + b, 0);
        const creditTotal = model.credits.map(o => o.amount).reduce((a, b) => a + b, 0);
        if (debitTotal !== creditTotal) newErrors.push('Total Debit should match Total Credit');

        setErrors(newErrors);

        if (newErrors.length > 0) return;

        const value = Object.assign({}, model);
        if(!value.narration) value.narration = null;

        if (mode === 'Create') {
            axios({
                method: 'POST',
                url: '/journalvoucher',
                headers: { 'X-SocietyId': selectedSociety },
                data: JSON.stringify(value)
            })
                .then(response => {
                    blankFormValue.date = model.date;

                    setModel(Object.assign({}, blankFormValue));
                    setRecordAdded(recordAdded + 1);

                    enqueueSnackbar('Saved', { variant: 'success' });
                    setTimeout(() => document.getElementById('debitaccount').focus(), 200);
                })
                .catch(error => {
                    let newErrors = ['Error: ' + error.response.data.message];
                    setErrors(newErrors);
                });
        } else {
            axios({
                method: 'PUT',
                url: '/journalvoucher/' + model.docNo,
                headers: { 'X-SocietyId': selectedSociety },
                data: JSON.stringify(value)
            })
                .then(response => {
                    blankFormValue.date = model.date;

                    setModel(Object.assign({}, blankFormValue));
                    setRecordAdded(recordAdded + 1);
                    setMode('Create');

                    enqueueSnackbar('Saved', { variant: 'success' });
                    setTimeout(() => document.getElementById('debitaccount').focus(), 200);
                })
                .catch(error => {
                    let newErrors = ['Error: ' + error.response.data.message];
                    setErrors(newErrors);
                });
        }
    }

    function closeConfirmDeleteDialog() {
        setShowConfirmDelete(false);
    }

    function deleteTransaction(row) {
        closeConfirmDeleteDialog();
        axios({
            method: 'DELETE',
            url: '/journalvoucher/' + row.docNo,
            headers: { 'X-SocietyId': selectedSociety }
        })
            .then(response => {
                setRefreshTransactions(refreshTransactions + 1);
            });
    }

    function addDebitEntry() {
        const entry = { account: { code: newDebitEntry.account.code, title: newDebitEntry.account.title }, amount: newDebitEntry.amount };

        if (!entry.account.code || !entry.amount) {
            alert("Account and Amount are required!");
            setTimeout(() => document.getElementById('debitaccount').focus(), 200);
            return;
        }

        model.debits.push(entry);
        setModel(Object.assign({}, model));
        setNewDebitEntry(Object.assign({}, blankNewEntryValue));
        setTimeout(() => document.getElementById('debitaccount').focus(), 200);
    }

    function addCreditEntry() {
        const entry = { account: { code: newCreditEntry.account.code, title: newCreditEntry.account.title }, amount: newCreditEntry.amount };

        if (!entry.account.code || !entry.amount) {
            alert("Account and Amount are required!");
            setTimeout(() => document.getElementById('creditaccount').focus(), 200);
            return;
        }

        model.credits.push(entry);
        setModel(Object.assign({}, model));
        setNewCreditEntry(Object.assign({}, blankNewEntryValue));
        setTimeout(() => document.getElementById('creditaccount').focus(), 200);
    }

    function deleteDebitEntry(entry) {
        var index = model.debits.indexOf(entry);
        if (index !== -1) model.debits.splice(index, 1);
        setModel(Object.assign({}, model));
    }

    function deleteCreditEntry(entry) {
        var index = model.credits.indexOf(entry);
        if (index !== -1) model.credits.splice(index, 1);
        setModel(Object.assign({}, model));
    }

    return (
        <>
            <form key={recordAdded} autoComplete="off" onKeyDown={(e) => { if (e.keyCode === 13 && (e.target.id !== 'save' && e.target.id !== 'addDebitEntry' && e.target.id !== 'addCreditEntry' )) e.preventDefault(); }}>
                <div>
                    <FormControl className={classes.formControlFullWidth}>
                        <TextField
                            type="date"
                            name="date"
                            label="Date"
                            fullWidth
                            variant="outlined"
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            value={model.date}
                            onChange={(e) => { setModel({ ...model, date: moment(e.target.value).format('YYYY-MM-DD') }) }}
                            onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('debitaccount').focus(); } }}
                        />
                    </FormControl>
                </div>
                <div>
                    <Grid container>
                        <Grid item xs={6}>
                            <h4>Debits - {model.debits.map(o => o.amount).reduce((a, b) => a + b, 0)}</h4>
                            {model.debits.map((debit, index) => (
                                <Grid key={index} container spacing={2}>
                                    <Grid item xs={7}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            {debit.account.code} - {debit.account.title}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            {debit.amount}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <DeleteIcon className={classes.deleteEntry} onClick={() => deleteDebitEntry(debit)} />
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid container spacing={2}>
                                <Grid item xs={7}>
                                    <FormControl className={classes.formControlFullWidth}>
                                        <Autocomplete
                                            id="debitaccount"
                                            name="code"
                                            value={newDebitEntry.account}
                                            options={accounts}
                                            getOptionLabel={option => option.title}
                                            selectOnFocus
                                            disableOpenOnFocus
                                            renderInput={params => <TextField {...params} variant="outlined" size="small" InputLabelProps={{ shrink: true }} label="Account" />}
                                            onChange={(_, value) => {
                                                if(value === null) value = blankNewEntryValue.account;
                                                setNewDebitEntry({ ...newDebitEntry, account: value });
                                            }}
                                            title={newDebitEntry.account.groupName}
                                            onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('debitamount').focus(); } }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl className={classes.formControlFullWidth}>
                                        <TextField
                                            id="debitamount"
                                            type="number"
                                            name="amount"
                                            value={newDebitEntry.amount}
                                            label="Amount"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) => { setNewDebitEntry({ ...newDebitEntry, amount: parseFloat(e.target.value) }) }}
                                            onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('addDebitEntry').focus(); } }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <Button id="addDebitEntry" className={classes.deleteEntry} size="small" onClick={addDebitEntry}>Add</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <h4>Credits - {model.credits.map(o => o.amount).reduce((a, b) => a + b, 0)}</h4>
                            {model.credits.map((credit, index) => (
                                <Grid key={index} container spacing={2}>
                                    <Grid item xs={7}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            {credit.account.code} - {credit.account.title}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            {credit.amount}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <DeleteIcon className={classes.deleteEntry} onClick={() => deleteCreditEntry(credit)} />
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid container spacing={2}>
                                <Grid item xs={7}>
                                    <FormControl className={classes.formControlFullWidth}>
                                        <Autocomplete
                                            id="creditaccount"
                                            name="code"
                                            value={newCreditEntry.account}
                                            options={accounts}
                                            getOptionLabel={option => option.title}
                                            selectOnFocus
                                            disableOpenOnFocus
                                            renderInput={params => <TextField {...params} variant="outlined" size="small" InputLabelProps={{ shrink: true }} label="Account" />}
                                            onChange={(_, value) => {
                                                if(value === null) value = blankNewEntryValue.account;
                                                setNewCreditEntry({ ...newCreditEntry, account: value });
                                            }}
                                            title={newCreditEntry.account.groupName}
                                            onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('creditamount').focus(); } }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl className={classes.formControlFullWidth}>
                                        <TextField
                                            id="creditamount"
                                            type="number"
                                            name="amount"
                                            value={newCreditEntry.amount}
                                            label="Amount"
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(e) => { setNewCreditEntry({ ...newCreditEntry, amount: parseFloat(e.target.value) }) }}
                                            onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('addCreditEntry').focus(); } }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={1}>
                                    <Button id="addCreditEntry" className={classes.deleteEntry} size="small" onClick={addCreditEntry}>Add</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <b>Difference:</b> {model.debits.map(o => o.amount).reduce((a, b) => a + b, 0) - model.credits.map(o => o.amount).reduce((a, b) => a + b, 0)}
                </div>
                <div>
                    <FormControl className={classes.formControlFullWidth}>
                        <Autocomplete
                            id="narration"
                            name="narration"
                            options={narrations.map(option => option.description)}
                            freeSolo={true}
                            disableOpenOnFocus
                            renderInput={params => <TextField {...params} InputLabelProps={{ shrink: true }} size="small" label="Narration" />}
                            value={model.narration}
                            onChange={(_, value) => { setModel({ ...model, narration: value }) }}
                            onKeyUp={(e) => { setModel({ ...model, narration: e.target.value }); if (e.keyCode === 13) { document.getElementById('save').focus(); } }}
                        />
                    </FormControl>
                </div>
                <div>
                    {errors.map((error, index) => <div key={index}>{error}</div>)}
                </div>
                <div>
                    <Button id="save" variant="contained" size="small" color="primary" type="button" onClick={save}>
                        Save
                    </Button>
                </div>
            </form>

            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Vch No</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Narration</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.map((row, index) => (
                            <TableRow hover key={index}>
                                <TableCell>{row.docNo}</TableCell>
                                <TableCell>{moment(row.date).format('DD/MM/YYYY')}</TableCell>
                                <TableCell>{formatMoney(row.amount)}</TableCell>
                                <TableCell>{row.narration}</TableCell>
                                <TableCell>
                                    <EditIcon className={classes.actionButton} onClick={() => edit(row)} />
                                    <DeleteIcon className={classes.actionButton} onClick={() => ddelete(row)} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button onClick={loadMore}>More...</Button>

            <ConfirmDialog model={deleteModel}
                show={showConfirmDelete}
                title="Confirm Delete"
                message="Are you sure you want to delete this transaction?"
                onClose={closeConfirmDeleteDialog}
                onYes={deleteTransaction} />
        </>
    );
}

export default connect(mapToProps, actions)(BankReceipts);