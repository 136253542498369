import React, { useState } from 'react';
import { Button, CssBaseline, TextField, Link, Grid, Box, Typography, Container, makeStyles } from '@material-ui/core';
import { connect } from 'redux-zero/react';
import actions from '../actions';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sharpsociety.in">
        Sharp Accounting Services
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  error: {
    color: 'red'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginBottom: 10
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cityscape: {
    zIndex: -1,
    opacity: 0.4,
    position: 'fixed',
    left: 0,
    bottom: 0,
    height: 250,
    width: '1000%',
    backgroundImage: 'url(/images/cityscape.png)',
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'bottom left',
    animation: 'slide 90s linear infinite'
  }
}));

const mapToProps = ({ isAuthenticated }) => ({ isAuthenticated });

function SignIn({ setIsAuthenticated }) {
  const classes = useStyles();

  const [model, setModel] = useState({ emailOrMobile: '', password: '' });
  const [error, setError] = useState('');
  const history = useHistory();

  function signIn() {
    axios({
      method: 'POST',
      url: '/user/signin',
      data: JSON.stringify(model)
    })
      .then(response => {
        setError('');
        setIsAuthenticated(true);
        history.push('/');
      })
      .catch(error => {
        setIsAuthenticated(false);
        setError('Invalid email or password');
      });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.logo} src="images/sharp_logo3.svg" alt="Sharp Logo" />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <div className={classes.form}>
          <TextField variant="outlined" margin="normal" required fullWidth id="emailOrMobile" label="Email or Mobile" name="emailOrMobile" autoComplete="emailOrMobile" autoFocus
            onChange={(e) => setModel({ ...model, emailOrMobile: e.target.value })}
            onKeyUp={(e) => { if (e.keyCode === 13) { signIn() } }} />
          <TextField variant="outlined" margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password"
            onChange={(e) => setModel({ ...model, password: e.target.value })}
            onKeyUp={(e) => { if (e.keyCode === 13) { signIn() } }} />
          <div className={classes.error}>
            {error}
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={signIn}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/resetpassword" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <div className={classes.cityscape}>
      </div>
    </Container>
  );
}

export default connect(mapToProps, actions)(SignIn);