import React, { useState } from 'react';
import { Button, FormControl, Modal, makeStyles, MenuItem, Grid } from '@material-ui/core';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import SharpTextField from '../SharpTextField';
import SharpSelectField from '../SharpSelectField';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 500
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 800
    },
    formControlFullWidth: {
        margin: theme.spacing(1, 0),
        width: '100%',
    },
}));

function BusinessEdit({ mode, model, onClose }) {
    const classes = useStyles();
    const [error, setError] = useState('');

    function onSubmit(value) {
        setError('');

        if (mode === 'Create') {
            axios({
                method: 'POST',
                url: '/business',
                data: JSON.stringify(value)
            })
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    setError(error.response.data.message);
                });
        } else if (mode === 'Edit') {
            axios({
                method: 'PUT',
                url: '/business/' + model.id,
                data: JSON.stringify(value)
            })
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    setError(error.response.data.message);
                });
        }
    }

    return (
        <Modal className={classes.modal} open={(model != null)} onClose={onClose}>
            <div className={classes.paper}>
                <h2 id="transition-modal-title">{mode} Business</h2>
                <Formik initialValues={model}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        name: Yup.string().required('Required'),
                        address: Yup.string().required('Required'),
                        contactName: Yup.string(),
                        email: Yup.string(),
                        telephone: Yup.string(),
                        mobile: Yup.string(),
                        packageName: Yup.string().required('Required'),
                        packageExpiry: Yup.date().required('Required'),
                        maxBusinessUsers: Yup.number().required('Required'),
                        maxSocieties: Yup.number().required('Required'),
                    })}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {({ isValid }) => (
                        <Form autoComplete="off" onKeyDown={(e) => { if (e.keyCode === 13 && e.target.id !== 'save') e.preventDefault(); }}>
                            <div>
                                <FormControl className={classes.formControlFullWidth}>
                                    <SharpTextField
                                        id="name"
                                        name="name"
                                        label="Name"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('address').focus(); } }}
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl className={classes.formControlFullWidth}>
                                    <SharpTextField
                                        id="address"
                                        name="address"
                                        label="Address"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('contactName').focus(); } }}
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="contactName"
                                                name="contactName"
                                                label="Contact Name"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('email').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="email"
                                                name="email"
                                                label="Email"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('telephone').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="telephone"
                                                name="telephone"
                                                label="Telephone"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('mobile').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="mobile"
                                                name="mobile"
                                                label="Mobile"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('packageName').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpSelectField
                                                id="packageName"
                                                name="packageName"
                                                label="Package"
                                                fullWidth
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('packageExpiry').focus(); } }}
                                            >
                                                <MenuItem value={'Free'}>Free</MenuItem>
                                                <MenuItem value={'Premium'}>Premium</MenuItem>
                                                <MenuItem value={'Trial'}>Trial</MenuItem>
                                            </SharpSelectField>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="packageExpiry"
                                                name="packageExpiry"
                                                label="Package Expiry"
                                                type="date"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('maxSocieties').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="maxSocieties"
                                                name="maxSocieties"
                                                label="Max Societies"
                                                type="number"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('maxBusinessUsers').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="maxBusinessUsers"
                                                name="maxBusinessUsers"
                                                label="Max Business Users"
                                                type="number"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('save').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                {error}
                            </div>
                            <div>
                                <Button id="save" variant="contained" color="primary" type="submit" disabled={!isValid}>
                                    Save
                                </Button>
                                <Button variant="contained" color="secondary" type="submit" onClick={() => { setError(''); onClose(); }}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
}

export default BusinessEdit;