import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Drawer,
  AppBar,
  Menu,
  MenuItem,
  Button,
  CssBaseline,
  Toolbar,
  Collapse,
  List,
  Typography,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  Apartment as ApartmentIcon,
  TrendingUp as TrendingUpIcon,
  MonetizationOn as MonetizationOnIcon,
  Receipt as ReceiptIcon,
  AccountBalance as AccountBalanceIcon,
  SwapHoriz as SwapHorizIcon,
  ExpandLess,
  ExpandMore,
  Business as BusinessIcon,
  People as PeopleIcon,
} from "@material-ui/icons";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import { connect } from "redux-zero/react";
import store from "../store";
import actions from "../actions";
import Register from "./Register";
import Registered from "./Registered";
import SignIn from "./SignIn";
import Activate from "./Activate";
import ResetPassword from "./ResetPassword";
import ChangePassword from "./ChangePassword";
import SwitchSociety from "./SwitchSociety";
// import Backups from './backup/Backups';
import SocietyDetails from "./society/SocietyDetails";
import AccountGroups from "./accountgroup/AccountGroups";
import Accounts from "./account/Accounts";
import BillItems from "./billitem/BillItems";
import Members from "./member/Members";
import OpeningBalances from "./openingbalance/OpeningBalances";
import BillMasters from "./billmaster/BillMasters";
import Bills from "./bill/Bills";
import BillsView from "./bill/BillsView";
import Transactions from "./transaction/Transactions";
import BankReconciliation from "./bankreconciliation/BankReconciliation";
import ReceiptsVouchersReport from "./report/ReceiptsVouchersReport";
import JournalVouchersReport from "./report/JournalVouchersReport";
import MemberBalanceReport from "./report/MemberBalanceReport";
import LedgerReport from "./report/LedgerReport";
import MultipleLedgersReport from "./report/MultipleLedgersReport";
import TrialBalanceReport from "./report/TrialBalanceReport";
import BalanceSheetReport from "./report/BalanceSheetReport";
import IncomeExpenditureReport from "./report/IncomeExpenditureReport";
import Businesses from "./business/Businesses";
import BusinessUsers from "./business/BusinessUsers";
import BusinessSocieties from "./business/BusinessSocieties";
import BillContributionsReport from "./report/BillContributionsReport";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  nested: {
    paddingLeft: theme.spacing(9),
  },
  societyName: {
    marginLeft: 80,
    flex: 1,
  },
  link: {
    cursor: "pointer",
  },
  userMenuButton: {
    color: "#fff",
  },
}));

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        store.getState().isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const mapToProps = ({
  isAuthenticated,
  selectedSociety,
  selectedSocietyName,
}) => ({ isAuthenticated, selectedSociety, selectedSocietyName });

function Layout({
  setIsAuthenticated,
  isAuthenticated,
  selectedSociety,
  selectedSocietyName,
}) {
  const history = useHistory();
  const classes = useStyles();

  const [accountsOpen, setAccountsOpen] = useState(false);
  const [billingOpen, setBillingOpen] = useState(false);
  const [reportsOpen, setReportsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function changePassword() {
    handleClose();
    history.push("/changepassword");
  }

  function signOut() {
    handleClose();
    localStorage.removeItem("accessToken");
    setIsAuthenticated(false);
    history.push("/signin");
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      {isAuthenticated && (
        <>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" noWrap>
                Sharp Society
              </Typography>
              <Typography className={classes.societyName} variant="h6" noWrap>
                {selectedSocietyName}
              </Typography>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                className={classes.userMenuButton}
                onClick={handleClick}
              >
                {window.decodedJwt.username}
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={changePassword}>Change Password</MenuItem>
                <MenuItem onClick={signOut}>Sign out</MenuItem>
              </Menu>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            display="none"
          >
            <div className={classes.toolbar} />
            <List>
              <ListItem button component={Link} to="/" key="Home">
                <ListItemIcon>
                  <SwapHorizIcon />
                </ListItemIcon>
                <ListItemText primary="Switch Society" />
              </ListItem>
              {selectedSociety === 0 &&
                window.decodedJwt.userType === "Sharp" && (
                  <ListItem
                    button
                    component={Link}
                    to="/businesses"
                    key="Business"
                  >
                    <ListItemIcon>
                      <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary="Businesses" />
                  </ListItem>
                )}
              {selectedSociety === 0 &&
                window.decodedJwt.userType === "Business" && (
                  <ListItem
                    button
                    component={Link}
                    to="/businessusers"
                    key="Users"
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItem>
                )}
              {selectedSociety > 0 && (
                <>
                  <ListItem
                    button
                    component={Link}
                    to="/societydetails"
                    key="SocietyDetails"
                  >
                    <ListItemIcon>
                      <ApartmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Society Details" />
                  </ListItem>
                  <ListItem
                    button
                    key="Accounts"
                    onClick={() => setAccountsOpen(!accountsOpen)}
                  >
                    <ListItemIcon>
                      <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Accounts" />
                    {accountsOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={accountsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/accountgroups"
                        key="AccountGroups"
                      >
                        <ListItemText primary="Accounts Groups" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/accounts"
                        key="Accounts"
                      >
                        <ListItemText primary="Accounts" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/billitems"
                        key="BillItems"
                      >
                        <ListItemText primary="Bill Items" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/members"
                        key="Members"
                      >
                        <ListItemText primary="Members" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/openingbalances"
                        key="OpeningBalances"
                      >
                        <ListItemText primary="Opening Balances" />
                      </ListItem>
                    </List>
                  </Collapse>
                  <ListItem
                    button
                    key="Billing"
                    onClick={() => setBillingOpen(!billingOpen)}
                  >
                    <ListItemIcon>
                      <ReceiptIcon />
                    </ListItemIcon>
                    <ListItemText primary="Billing" />
                    {billingOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={billingOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/billmasters"
                        key="BillMasters"
                      >
                        <ListItemText primary="Bill Masters" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/bills"
                        key="Bills"
                      >
                        <ListItemText primary="Bills" />
                      </ListItem>
                    </List>
                  </Collapse>
                  <ListItem
                    button
                    component={Link}
                    to="/transactions"
                    key="Transactions"
                  >
                    <ListItemIcon>
                      <MonetizationOnIcon />
                    </ListItemIcon>
                    <ListItemText primary="Transactions" />
                  </ListItem>
                  {/* <ListItem button component={Link} to="/bankreconciliation" key="BankReconciliation">
                    <ListItemIcon><CheckIcon /></ListItemIcon>
                    <ListItemText primary="Bank Reconciliation" />
                  </ListItem> */}
                  <ListItem
                    button
                    key="Reports"
                    onClick={() => setReportsOpen(!reportsOpen)}
                  >
                    <ListItemIcon>
                      <TrendingUpIcon />
                    </ListItemIcon>
                    <ListItemText primary="Reports" />
                    {reportsOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={reportsOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/reports/receiptsvouchers"
                        key="ReceiptsVouchers"
                      >
                        <ListItemText primary="Receipts/Vouchers" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/reports/journalvouchers"
                        key="JournalVouchers"
                      >
                        <ListItemText primary="Journal Vouchers" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/reports/memberbalance"
                        key="MemberBalance"
                      >
                        <ListItemText primary="Member Balance" />
                      </ListItem>
                      <Divider />
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/reports/ledger"
                        key="Ledger"
                      >
                        <ListItemText primary="Ledger" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/reports/multipleledgers"
                        key="MultipleLedgers"
                      >
                        <ListItemText primary="Multiple Ledgers" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/reports/billcontributions"
                        key="BillContributions"
                      >
                        <ListItemText primary="Bill Contributions" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/reports/trialbalance"
                        key="TrialBalance"
                      >
                        <ListItemText primary="Trial Balance" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/reports/balancesheet"
                        key="BalanceSheet"
                      >
                        <ListItemText primary="Balance Sheet" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        className={classes.nested}
                        to="/reports/incomeexpenditure"
                        key="IncomeExpenditure"
                      >
                        <ListItemText primary="Income Expenditure" />
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )}
            </List>
            <Divider />
          </Drawer>
        </>
      )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route path="/register" component={Register} />
          <Route path="/registered" component={Registered} />
          <Route path="/signin" component={SignIn} />
          <Route path="/activate" component={Activate} />
          <Route path="/resetpassword" component={ResetPassword} />
          <Route path="/changepassword" component={ChangePassword} />
          <PrivateRoute path="/" exact>
            <SwitchSociety />
          </PrivateRoute>
          {/* <PrivateRoute path="/backups"><Backups /></PrivateRoute> */}
          <PrivateRoute path="/societydetails">
            <SocietyDetails />
          </PrivateRoute>
          <PrivateRoute path="/accountgroups">
            <AccountGroups />
          </PrivateRoute>
          <PrivateRoute path="/accounts">
            <Accounts />
          </PrivateRoute>
          <PrivateRoute path="/billitems">
            <BillItems />
          </PrivateRoute>
          <PrivateRoute path="/members">
            <Members />
          </PrivateRoute>
          <PrivateRoute path="/openingbalances">
            <OpeningBalances />
          </PrivateRoute>
          <PrivateRoute path="/billmasters">
            <BillMasters />
          </PrivateRoute>
          <PrivateRoute path="/bills" exact>
            <Bills />
          </PrivateRoute>
          <PrivateRoute path="/bills/:billdate" exact>
            <BillsView />
          </PrivateRoute>
          <PrivateRoute path="/transactions">
            <Transactions />
          </PrivateRoute>
          <PrivateRoute path="/bankreconciliation">
            <BankReconciliation />
          </PrivateRoute>
          <PrivateRoute path="/reports/receiptsvouchers">
            <ReceiptsVouchersReport />
          </PrivateRoute>
          <PrivateRoute path="/reports/journalvouchers">
            <JournalVouchersReport />
          </PrivateRoute>
          <PrivateRoute path="/reports/memberbalance">
            <MemberBalanceReport />
          </PrivateRoute>
          <PrivateRoute path="/reports/ledger">
            <LedgerReport />
          </PrivateRoute>
          <PrivateRoute path="/reports/multipleledgers">
            <MultipleLedgersReport />
          </PrivateRoute>
          <PrivateRoute path="/reports/billcontributions">
            <BillContributionsReport />
          </PrivateRoute>
          <PrivateRoute path="/reports/trialbalance">
            <TrialBalanceReport />
          </PrivateRoute>
          <PrivateRoute path="/reports/balancesheet">
            <BalanceSheetReport />
          </PrivateRoute>
          <PrivateRoute path="/reports/incomeexpenditure">
            <IncomeExpenditureReport />
          </PrivateRoute>
          <PrivateRoute path="/businessusers">
            <BusinessUsers />
          </PrivateRoute>
          <PrivateRoute path="/businesses" exact>
            <Businesses />
          </PrivateRoute>
          <PrivateRoute path="/businesses/:id/users" exact>
            <BusinessUsers />
          </PrivateRoute>
          <PrivateRoute path="/businesses/:id/societies" exact>
            <BusinessSocieties />
          </PrivateRoute>
        </Switch>
      </main>
    </div>
  );
}

export default connect(mapToProps, actions)(Layout);
