import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Typography, TextField, Button, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, CircularProgress, Fade, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginBottom: 10
    },
    topBarControl: {
        float: 'left',
        marginRight: 10
    },
    hidden: {
        display: 'none'
    },
    amount: {
        width: 80
    }
}));

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function BillMasters({ selectedSociety }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = React.useState(false);
    const [refreshData, setRefreshData] = React.useState(1);
    const [refreshTable, setRefreshTable] = React.useState(1);
    const [billMasters, setBillMasters] = useState([]);
    const [selectedMaster, setSelectedMaster] = useState({});
    const [billMaster, setBillMaster] = useState({ billItems: [], items: [] });
    const [changedCodes] = useState(new Set());
    const [focusedInput, setFocusedInput] = useState(null);

    useEffect(() => {
        setLoading(true);

        axios.get('/billmaster/list', { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setBillMasters(response.data);
            });
    },
        [selectedSociety, refreshData]);

    useEffect(() => {
        if (billMasters.length > 0) {
            let current = billMasters.find(o => o.name === 'Current');
            setSelectedMaster(current);
        }
    }, [setSelectedMaster, billMasters]);

    useEffect(() => {
        if (selectedMaster.id) {
            axios.get('/billmaster/items/' + selectedMaster.id, { headers: { 'X-SocietyId': selectedSociety } })
                .then(response => {
                    let v = response.data;
                    for (let item of v.items) {
                        calculateTotal(item);
                    }
                    setBillMaster(v);
                    setRefreshTable(refreshTable + 1);
                    setLoading(false);
                })
                .catch(response => {
                    setLoading(false);
                });
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedMaster]);

    function calculateTotal(item) {
        item.total = item.a1 + item.a2 + item.a3 + item.a4 + item.a5 + item.a6 + item.a7 + item.a8 + item.a9 + item.a10 +
            item.a11 + item.a12 + item.a13 + item.a14 + item.a15 + item.a16 + item.a17 + item.a18 + item.a19 + item.a20;
    }

    function handleRowChange(e, row, a) {
        changedCodes.add(row.memberAccountCode);
        row[a] = parseFloat(e.target.value);
        calculateTotal(row);
        let totalCell = document.getElementById('total_' + row.memberAccountCode);
        totalCell.innerHTML = row.total;
    }

    function renderBillItemHeaders() {
        var ret = [];
        for (let billItem of billMaster.billItems) {
            var c = <TableCell key={billItem.name}>{billItem.name}</TableCell>;
            ret.push(c);
        }
        ret.push(<TableCell>Total</TableCell>);
        return ret;
    }

    function renderBillItemAmountCells(row) {
        var ret = [];
        for (let billItem of billMaster.billItems) {
            ret.push(
                <TableCell key={billItem.name}>
                    <TextField type="number" className={classes.amount}
                        id={'a' + billItem.itemIndex + '_' + row.memberAccountCode}
                        inputProps={{ code: row.memberAccountCode }}
                        defaultValue={row['a' + billItem.itemIndex]}
                        onChange={(e) => handleRowChange(e, row, 'a' + billItem.itemIndex)}
                        onFocus={(e) => setFocusedInput(e.target)}
                        onKeyUp={(e) => {
                            if (e.keyCode === 13) {
                                let form = e.target.form;
                                let index = Array.prototype.indexOf.call(form, e.target);
                                let nextElement = form.elements[index + billMaster.billItems.length * 2];
                                if (nextElement) {
                                    nextElement.focus();
                                    nextElement.select();
                                }
                            }
                        }}
                    />
                </TableCell>);
        }
        ret.push(<TableCell id={'total_' + row.memberAccountCode}>{row.total}</TableCell>);
        return ret;
    }

    function save() {
        let changedRows = [];
        for (let code of changedCodes) {
            var r = {
                billMasterId: selectedMaster.id, memberAccountCode: code,
                a1: 0, a2: 0, a3: 0, a4: 0, a5: 0, a6: 0, a7: 0, a8: 0, a9: 0, a10: 0,
                a11: 0, a12: 0, a13: 0, a14: 0, a15: 0, a16: 0, a17: 0, a18: 0, a19: 0, a20: 0
            };
            for (let i = 1; i <= 20; i++) {
                var id = 'a' + i + '_' + code;
                let t = document.getElementById(id);
                if (t) {
                    if (!t.value) t.value = 0;
                    r['a' + i] = parseFloat(t.value);
                } else {
                    delete r['a' + i];
                }
            }

            changedRows.push(r);
        }

        if (changedRows.length > 0) {
            axios({
                method: 'PUT',
                url: '/billmaster/items',
                headers: { 'X-SocietyId': selectedSociety },
                data: JSON.stringify(changedRows)
            })
                .then(response => {
                    changedCodes.clear();
                    setRefreshData(refreshData + 1);
                    enqueueSnackbar('Saved', { variant: 'success' });
                })
                .catch(error => {
                    alert(error.response.data.message);
                });
        } else {
            enqueueSnackbar('No changes have been made');
        }
    }

    function copyDown() {
        if (!focusedInput) return;

        var form = focusedInput.form;
        var index = Array.prototype.indexOf.call(form, focusedInput);

        while (true) {
            var nextIndex = index + billMaster.billItems.length * 2;

            if (nextIndex < form.elements.length) {
                var nextInput = form.elements[nextIndex];
                nextInput.value = focusedInput.value;
                var code = parseInt(nextInput.getAttribute('code'));
                changedCodes.add(code);
                index = nextIndex;
            } else {
                break;
            }
        }
    }

    return (
        <>
            <Typography variant="h5">Bill Masters</Typography>
            <div className={classes.topBar}>
                <Autocomplete
                    className={classes.topBarControl}
                    style={{ width: 400 }}
                    size="small"
                    options={billMasters}
                    getOptionLabel={option => option.name}
                    selectOnFocus
                    disableOpenOnFocus
                    value={selectedMaster}
                    renderInput={params => <TextField {...params} InputLabelProps={{ shrink: true }} />}
                    onChange={(_, value) => { if (value) { setSelectedMaster(value); } }}
                />
                {window.decodedJwt.role === 'Administrator' && <>
                    <Button className={classes.topBarControl} variant="contained" size="small" color="primary" onClick={save} disabled={selectedMaster.name !== 'Current'}>Save</Button>
                    <Button className={classes.topBarControl} variant="contained" size="small" color="primary" onClick={copyDown} disabled={selectedMaster.name !== 'Current'}>Copy Down</Button>
                </>
                }
                <Fade
                    in={loading}
                    style={{
                        transitionDelay: '0ms',
                    }}
                    unmountOnExit
                >
                    <CircularProgress />
                </Fade>
            </div>
            <form autoComplete="off" onKeyDown={(e) => { if (e.keyCode === 13) e.preventDefault(); }}>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell key="code">Code</TableCell>
                                <TableCell key="building">Building</TableCell>
                                <TableCell key="wing">Wing</TableCell>
                                <TableCell key="flat">Flat</TableCell>
                                <TableCell key="name">Name</TableCell>
                                {renderBillItemHeaders()}
                            </TableRow>
                        </TableHead>
                        {<TableBody key={refreshTable}>
                            {billMaster.items.map((row, index) => (
                                <TableRow hover key={index}>
                                    <TableCell>{row.memberAccountCode}</TableCell>
                                    <TableCell>{row.buildingNo}</TableCell>
                                    <TableCell>{row.wing}</TableCell>
                                    <TableCell>{row.unit}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    {renderBillItemAmountCells(row)}
                                </TableRow>
                            ))}
                        </TableBody>}
                    </Table>
                </TableContainer>
            </form>
        </>
    );
}

export default connect(mapToProps, actions)(BillMasters);