import React, { useState, useEffect } from 'react';
import { Typography, Button, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DateRangeIcon from '@material-ui/icons/DateRange';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import BillItemEdit from './BillItemEdit';
import ConfirmDialog from '../ConfirmDialog';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginBottom: 10
    },
    actionButton: {
        cursor: 'pointer'
    },
    recurrenceText: {
        verticalAlign: 'text-bottom'
    }
}));

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function BillItems({ selectedSociety }) {
    const classes = useStyles();

    const [refreshData, setRefreshData] = React.useState(1);
    const [editMode, setEditMode] = React.useState(null);
    const [editModel, setEditModel] = React.useState();
    const [billItems, setBillItems] = useState([]);
    const [deleteModel, setDeleteModel] = React.useState();
    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

    useEffect(() => {
        axios.get('/billitem/list', { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setBillItems(response.data);
            });
    }, [selectedSociety, refreshData]);

    function create() {
        setEditMode('Create');
        setEditModel({ id: 0, code: '', name: '', type: 'Income', chargeInterest: true, recurrence: 'Every bill', highlight: false, isSystem: false });
    }

    function edit(row) {
        setEditMode('Edit');
        setEditModel(row);
    }

    function closeEditModal() {
        setEditModel(null);
        setRefreshData(refreshData + 1);
    }

    function ddelete(row) {
        setDeleteModel(row);
        setShowConfirmDelete(true);
    }

    function closeConfirmDialog() {
        setShowConfirmDelete(false);
    }

    function deleteBillItem(row) {
        closeConfirmDialog();
        axios({
            method: 'DELETE',
            url: '/billitem/' + row.id,
            headers: { 'X-SocietyId': selectedSociety }
        })
            .then(response => {
                setRefreshData(refreshData + 1);
            });
    }

    // function toggleActive(row) {
    //     const isActive = row.billItemIndex !== -1;
    //     const action = isActive ? 'deactivate' : 'activate';
    //     const confirmed = window.confirm(`Are you sure you want to ${action} '${row.name}' bill item?`);
    //     if (confirmed) {
    //         axios({
    //             method: 'POST',
    //             url: `/billitem/${action}/${row.code}`,
    //             headers: { 'X-SocietyId': selectedSociety }
    //         })
    //             .then(response => {
    //                 setRefreshData(refreshData + 1);
    //             })
    //             .catch(error => {
    //                 enqueueSnackbar(error.response.data.message, { variant: 'danger' });
    //             });;
    //     }
    // }

    return (
        <>
            <Typography variant="h5">Bill Items</Typography>
            <div className={classes.topBar}>
                {window.decodedJwt.role === 'Administrator' &&
                    <Button variant="contained" size="small" color="primary" onClick={create}>Create</Button>
                }
            </div>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Recurrence</TableCell>
                            <TableCell>Charge Interest</TableCell>
                            <TableCell>Highlight</TableCell>
                            {/* <TableCell>Active</TableCell> */}
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {billItems.map((row, index) => (
                            <TableRow hover key={index}>
                                <TableCell>{row.code}</TableCell>
                                <TableCell>{row.highlight ? (<b>{row.name}</b>) : row.name}</TableCell>
                                <TableCell>{row.type}</TableCell>
                                <TableCell>
                                    <span className={classes.recurrenceText}>{row.recurrence}</span>
                                    {row.recurrence === 'Specific bills' && (
                                        <DateRangeIcon />
                                    )}
                                </TableCell>
                                <TableCell>{row.chargeInterest ? 'Yes' : 'No'}</TableCell>
                                <TableCell>{row.highlight ? 'Yes' : 'No'}</TableCell>
                                {/* <TableCell>
                                    <Switch checked={row.billItemIndex !== -1} onClick={() => toggleActive(row)}></Switch>
                                </TableCell> */}
                                <TableCell>
                                    {row.isSystem === false && window.decodedJwt.role === 'Administrator' && (
                                        <>
                                            <EditIcon className={classes.actionButton} onClick={() => edit(row)} />
                                            <DeleteIcon className={classes.actionButton} onClick={() => ddelete(row)} />
                                        </>)
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <BillItemEdit mode={editMode} model={editModel} onClose={closeEditModal} />
            <ConfirmDialog model={deleteModel}
                show={showConfirmDelete}
                title="Confirm Delete"
                message="Are you sure you want to delete this bill item?"
                onClose={closeConfirmDialog}
                onYes={deleteBillItem} />
        </>
    );
}

export default connect(mapToProps, actions)(BillItems);