import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Typography, Button, FormControl, makeStyles } from '@material-ui/core';
import { Formik, Form } from 'formik';
import SharpTextField from '../SharpTextField';
import SharpBillingCycle from '../SharpBillingCycle';
import axios from 'axios';
import * as Yup from 'yup';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import moment from 'moment';

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

const useStyles = makeStyles(theme => ({
    formControlFullWidth: {
        margin: theme.spacing(1, 0),
        width: '100%',
    },
    formControl: {
        margin: theme.spacing(1, 0),
        width: '49%',
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1, 0),
        marginRight: theme.spacing(1, 0),
        width: '49%',
    },
}));

function SocietyDetails({ selectedSociety, setSelectedSociety, setSelectedSocietyName }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [error, setError] = useState('');
    const [signatureImage, setSignatureImage] = useState('');

    let y = new Date().getFullYear();
    let accountingPeriodFrom;
    let accountingPeriodTo;
    if (new Date() < new Date(y, 3, 1)) {
        accountingPeriodFrom = new Date(y - 1, 3, 1);
        accountingPeriodTo = new Date(y, 2, 31);
    } else {
        accountingPeriodFrom = new Date(y, 3, 1);
        accountingPeriodTo = new Date(y + 1, 2, 31);
    }
    const [society, setSociety] = useState({
        name: '', nameLine2: '', regNo: '', addressLine1: '', addressLine2: '', addressLine3: '',
        authorisedShareCapital: '', bankName: '', bankAccountName: '', bankIFSC: '', bankAccountNumber: '',
        signatory: 'Hon. Secretary/Treasurer/Accountant', interestWording: 'Interest on Arrears',
        latePaymentInterestRate: 21.0, billingCycle: 'Monthly', payWithin: 0, billNotes: '', computerGeneratedText: 'This is a computer generated bill and does not require signature',
        accountingPeriodFrom: moment(accountingPeriodFrom).format('YYYY-MM-DD'), accountingPeriodTo: moment(accountingPeriodTo).format('YYYY-MM-DD'),
        currentPeriodFrom: moment(accountingPeriodFrom).format('YYYY-MM-DD'), currentPeriodTo: moment(accountingPeriodTo).format('YYYY-MM-DD')
    });

    useEffect(() => {
        if (selectedSociety > 0) {
            axios.get('/society/' + selectedSociety)
                .then(response => {
                    let v = response.data;
                    v.accountingPeriodTo = response.data.accountingPeriodTo.substring(0, 10);
                    v.currentPeriodFrom = response.data.currentPeriodFrom.substring(0, 10);
                    v.currentPeriodTo = response.data.currentPeriodTo.substring(0, 10);
                    if (!v.addressLine2) v.addressLine2 = '';
                    if (!v.addressLine3) v.addressLine3 = '';
                    if (!v.authorisedShareCapital) v.authorisedShareCapital = '';
                    if (!v.bankName) v.bankName = '';
                    if (!v.bankAccountName) v.bankAccountName = '';
                    if (!v.bankIFSC) v.bankIFSC = '';
                    if (!v.bankAccountNumber) v.bankAccountNumber = '';
                    if (!v.billNotes) v.billNotes = '';
                    if (!v.computerGeneratedText) v.computerGeneratedText = '';
                    setSociety(v);
                    document.getElementById('name').focus();
                });

            axios({
                method: 'GET',
                url: '/society/signatureimage',
                headers: { 'X-SocietyId': selectedSociety },
                responseType: 'arraybuffer'
            }).then(response => {
                const image = Buffer.from(response.data, 'binary').toString('base64');
                setSignatureImage(image);
            });;
        }
    }, [selectedSociety, signatureImage]);

    function uploadSignatureImage(e) {
        const formData = new FormData();
        formData.append('file', e.target.files[0])

        axios({
            method: 'POST',
            url: '/society/signatureimage',
            headers: { 'Content-Type': 'multipart/form-data', 'X-SocietyId': selectedSociety },
            data: formData
        })
            .then(response => {
                enqueueSnackbar('Uploaded', { variant: 'success' });
                setSignatureImage(' ');  // Just to trigger effect
            })
            .catch(error => {
                setError(error.response.data.message);
            });
    }

    function deleteSignatureImage() {
        if (window.confirm('Are you sure you want to delete the signature?')) {
            axios({
                method: 'DELETE',
                url: '/society/signatureimage',
                headers: { 'X-SocietyId': selectedSociety }
            })
                .then(response => {
                    enqueueSnackbar('Deleted', { variant: 'success' });
                    setSignatureImage('');  // Just to trigger effect
                })
                .catch(error => {
                    setError(error.response.data.message);
                });
        }
    }

    function onSubmit(value) {
        setError('');

        let v = { ...value };
        if (!v.addressLine2) v.addressLine2 = null;
        if (!v.addressLine3) v.addressLine3 = null;
        if (!v.authorisedShareCapital) v.authorisedShareCapital = null;
        if (!v.bankName) v.bankName = null;
        if (!v.bankAccountName) v.bankAccountName = null;
        if (!v.bankIFSC) v.bankIFSC = null;
        if (!v.bankAccountNumber) v.bankAccountNumber = null;
        if (!v.billNotes) v.billNotes = null;
        if (!v.computerGeneratedText) v.computerGeneratedText = null;

        if (selectedSociety > 0) {
            axios({
                method: 'PUT',
                url: '/society/' + selectedSociety,
                data: JSON.stringify(v)
            })
                .then(response => {
                    let v = response.data;
                    v.accountingPeriodFrom = response.data.accountingPeriodFrom.substring(0, 10);
                    v.accountingPeriodTo = response.data.accountingPeriodTo.substring(0, 10);
                    v.currentPeriodFrom = response.data.currentPeriodFrom.substring(0, 10);
                    v.currentPeriodTo = response.data.currentPeriodTo.substring(0, 10);
                    if (!v.addressLine2) v.addressLine2 = '';
                    if (!v.addressLine3) v.addressLine3 = '';
                    if (!v.authorisedShareCapital) v.authorisedShareCapital = '';
                    if (!v.bankName) v.bankName = '';
                    if (!v.bankAccountName) v.bankAccountName = '';
                    if (!v.bankIFSC) v.bankIFSC = '';
                    if (!v.bankAccountNumber) v.bankAccountNumber = '';
                    if (!v.billNotes) v.billNotes = '';
                    if (!v.computerGeneratedText) v.computerGeneratedText = '';
                    setSociety(v);
                    enqueueSnackbar('Saved', { variant: 'success' });
                })
                .catch(error => {
                    setError(error.response.data.message);
                });
        } else {
            axios({
                method: 'POST',
                url: '/society',
                data: JSON.stringify(v)
            })
                .then(response => {
                    let v = response.data;
                    v.accountingPeriodFrom = response.data.accountingPeriodFrom.substring(0, 10);
                    v.accountingPeriodTo = response.data.accountingPeriodTo.substring(0, 10);
                    v.currentPeriodFrom = response.data.currentPeriodFrom.substring(0, 10);
                    v.currentPeriodTo = response.data.currentPeriodTo.substring(0, 10);
                    if (!v.addressLine2) v.addressLine2 = '';
                    if (!v.addressLine3) v.addressLine3 = '';
                    if (!v.authorisedShareCapital) v.authorisedShareCapital = '';
                    if (!v.bankName) v.bankName = '';
                    if (!v.bankAccountName) v.bankAccountName = '';
                    if (!v.bankIFSC) v.bankIFSC = '';
                    if (!v.bankAccountNumber) v.bankAccountNumber = '';
                    if (!v.billNotes) v.billNotes = '';
                    if (!v.computerGeneratedText) v.computerGeneratedText = '';
                    setSociety(v);
                    setSelectedSociety(v.id, v.name);
                    setSelectedSocietyName(v.name);
                    enqueueSnackbar('Saved', { variant: 'success' });
                })
                .catch(error => {
                    setError(error.response.data.message);
                });
        }
    }

    return (
        <div>
            <Typography variant="h5">Society Details</Typography>
            <Formik initialValues={society}
                enableReinitialize={true}
                validationSchema={Yup.object({
                    name: Yup.string().required('Required'),
                    nameLine2: Yup.string(),
                    regNo: Yup.string().required('Required'),
                    addressLine1: Yup.string().required('Required'),
                    addressLine2: Yup.string().nullable(),
                    addressLine3: Yup.string().nullable(),
                    authorisedShareCapital: Yup.number().nullable(),
                    bankName: Yup.string().nullable(),
                    bankAccountName: Yup.string().nullable(),
                    bankIFSC: Yup.string().nullable(),
                    bankAccountNumber: Yup.string().nullable(),
                    signatory: Yup.string().required('Required'),
                    interestWording: Yup.string().required('Required'),
                    latePaymentInterestRate: Yup.number().required('Required').min(0.0, 'Should be between 0 and 21').max(21.0, 'Should be between 0 and 21'),
                    billingCycle: Yup.string().required('Required'),
                    payWithin: Yup.number().required('Required'),
                    billNotes: Yup.string().nullable(),
                    computerGeneratedText: Yup.string().nullable(),
                    accountingPeriodFrom: Yup.date().required('Required'),
                    accountingPeriodTo: Yup.date().required('Required'),
                    currentPeriodFrom: Yup.date().required('Required'),
                    currentPeriodTo: Yup.date().required('Required')
                })}
                validateOnMount={true}
                onSubmit={onSubmit}>
                {({ isValid }) => (
                    <Form autoComplete="off" onKeyDown={(e) => { if (e.keyCode === 13 && e.target.id !== 'save' && e.target.id !== 'billNotes') e.preventDefault(); }}>
                        <div>
                            <FormControl className={classes.formControlFullWidth}>
                                <SharpTextField
                                    id="name"
                                    name="name"
                                    label="Name"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('nameLine2').focus(); } }}
                                />
                            </FormControl>
                            <FormControl className={classes.formControlFullWidth}>
                                <SharpTextField
                                    id="nameLine2"
                                    name="nameLine2"
                                    fullWidth
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('regNo').focus(); } }}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="regNo"
                                    name="regNo"
                                    label="Registration No"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('addressLine1').focus(); } }}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.formControlFullWidth}>
                                <SharpTextField
                                    id="addressLine1"
                                    name="addressLine1"
                                    label="Address"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('addressLine2').focus(); } }}
                                />
                                <SharpTextField
                                    id="addressLine2"
                                    name="addressLine2"
                                    fullWidth
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('addressLine3').focus(); } }}
                                />
                                <SharpTextField
                                    id="addressLine3"
                                    name="addressLine3"
                                    fullWidth
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('signatory').focus(); } }}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="authorisedShareCapital"
                                    name="authorisedShareCapital"
                                    label="Authorised Share Capital (Rs.)"
                                    type="number"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('bankName').focus(); } }}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="bankName"
                                    name="bankName"
                                    label="Bank Name"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('bankAccountName').focus(); } }}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="bankAccountName"
                                    name="bankAccountName"
                                    label="Bank Account Name"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('latePaymentInterestRate').focus(); } }}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="bankIFSC"
                                    name="bankIFSC"
                                    label="Bank IFSC"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('bankAccountNumber').focus(); } }}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="bankAccountNumber"
                                    name="bankAccountNumber"
                                    label="Bank Account Number"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('signatory').focus(); } }}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="signatory"
                                    name="signatory"
                                    label="Signatory"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('interestWording').focus(); } }}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="interestWording"
                                    name="interestWording"
                                    label="Interest Wording"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('latePaymentInterestRate').focus(); } }}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="latePaymentInterestRate"
                                    name="latePaymentInterestRate"
                                    label="Late Payment Interest Rate (%)"
                                    type="number"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('billingCycle').focus(); } }}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <SharpBillingCycle
                                    id="billingCycle"
                                    name="billingCycle"
                                    label="Billing Cycle"
                                    fullWidth
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('payWithin').focus(); } }}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="payWithin"
                                    name="payWithin"
                                    label="Pay Bill Within (Days)"
                                    type="number"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('billNotes').focus(); } }}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="billNotes"
                                    name="billNotes"
                                    label="Bill Notes"
                                    multiline={true}
                                    rows="4"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="computerGeneratedText"
                                    name="computerGeneratedText"
                                    label="Computer Generated Bill Text"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('accountingPeriodFrom').focus(); } }}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="accountingPeriodFrom"
                                    name="accountingPeriodFrom"
                                    label="Accounting Period Start"
                                    type="date"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('accountingPeriodTo').focus(); } }}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="accountingPeriodTo"
                                    name="accountingPeriodTo"
                                    label="Accounting Period End"
                                    type="date"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('currentPeriodFrom').focus(); } }}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="currentPeriodFrom"
                                    name="currentPeriodFrom"
                                    label="Current Period Start"
                                    type="date"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('currentPeriodTo').focus(); } }}
                                />
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <SharpTextField
                                    id="currentPeriodTo"
                                    name="currentPeriodTo"
                                    label="Current Period End"
                                    type="date"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('save').focus(); } }}
                                />
                            </FormControl>
                        </div>
                        <div>
                            {error}
                        </div>
                        <div>
                            {window.decodedJwt.role === 'Administrator' && <Button
                                id="save"
                                variant="contained"
                                color="primary"
                                size="small"
                                type="submit"
                                disabled={!isValid}
                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('name').focus(); } }}>
                                {selectedSociety > 0 ? 'Save' : 'Create'}
                            </Button>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
            {selectedSociety > 0 &&
                <form>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <input
                            accept="image/gif"
                            className={classes.input}
                            id="contained-button-file"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={uploadSignatureImage}
                        />
                        {window.decodedJwt.role === 'Administrator' && <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" size="small" component="span">Upload Signature (GIF)</Button>
                        </label>
                        }
                    </div>
                    <div>
                        {signatureImage && (
                            <>
                                <img src={'data:image/gif;base64, ' + signatureImage} alt="Signature" />
                                {window.decodedJwt.role === 'Administrator' &&
                                    <Button variant="contained" color="secondary" size="small" component="span" onClick={deleteSignatureImage}>Delete</Button>
                                }
                            </>
                        )}
                    </div>
                </form>
            }
        </div>
    );
}

export default connect(mapToProps, actions)(SocietyDetails);