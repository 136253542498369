import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { Typography, Paper, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TextField, Checkbox, makeStyles } from '@material-ui/core';
import moment from 'moment';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginBottom: 10
    },
    topBarControl: {
        marginLeft: 10
    },
    amount: {
        width: 70
    }
}));

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function BillsView({ selectedSociety }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [refreshData, setRefreshData] = React.useState(1);
    const [billsResponse, setBillsResponse] = useState({ billItems: [], items: [] });
    const [changedBillNos] = useState(new Set());
    let { billdate } = useParams();

    useEffect(() => {
        axios.get('/bill/bills/' + billdate, { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                var v = response.data;
                for (let item of v.items) {
                    item.selected = true;
                }
                setBillsResponse(v);
            });
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedSociety, refreshData]);

    function renderBillItemHeaders() {
        var ret = [];
        for (let billItem of billsResponse.billItems) {
            var c = <TableCell key={billItem.name}>{billItem.name}</TableCell>;
            ret.push(c);
        }
        return ret;
    }

    function renderBillItemAmountCells(row) {
        var ret = [];
        for (let billItem of billsResponse.billItems) {
            ret.push(
                <TableCell key={billItem.name}>
                    <TextField type="number" className={classes.amount}
                        id={'a' + billItem.itemIndex + '_' + row.billNo}
                        inputProps={{ code: row.memberAccountCode }}
                        defaultValue={row['a' + billItem.itemIndex]}
                        onChange={() => changedBillNos.add(row.billNo)}
                        onKeyUp={(e) => {
                            if (e.keyCode === 13) {
                                let form = e.target.form;
                                let index = Array.prototype.indexOf.call(form, e.target);
                                let nextElement = form.elements[index + (billsResponse.billItems.length * 2) + 3];
                                if (nextElement) {
                                    nextElement.focus();
                                    nextElement.select();
                                }
                            }
                        }}
                    />
                </TableCell>);
        }
        return ret;
    }

    function save() {
        let changedRows = [];
        for (let billNo of changedBillNos) {
            let r = {
                billNo: billNo,
                a1: 0, a2: 0, a3: 0, a4: 0, a5: 0, a6: 0, a7: 0, a8: 0, a9: 0, a10: 0,
                a11: 0, a12: 0, a13: 0, a14: 0, a15: 0, a16: 0, a17: 0, a18: 0, a19: 0, a20: 0,
                billInterestOverride: 0
            };

            for (let i = 1; i <= 20; i++) {
                let id = 'a' + i + '_' + billNo;
                let t = document.getElementById(id);
                if (t) {
                    if (!t.value) t.value = 0;
                    r['a' + i] = parseFloat(t.value);
                } else {
                    delete r['a' + i];
                }
            }
            let bio = document.getElementById('billInterestOverride_' + billNo);
            if (bio.value === null) {
                r.billInterestOverride = null;
            } else {
                r.billInterestOverride = parseFloat(bio.value);
            }

            changedRows.push(r);
        }

        if (changedRows.length > 0) {
            axios({
                method: 'PUT',
                url: '/bill/bills',
                headers: { 'X-SocietyId': selectedSociety },
                data: JSON.stringify(changedRows)
            })
                .then(response => {
                    changedBillNos.clear();
                    setRefreshData(refreshData + 1);
                    enqueueSnackbar('Saved', { variant: 'success' });
                })
                .catch(error => {
                    alert(error.response.data.message);
                });
        }
        else {
            enqueueSnackbar('No changes', { variant: 'danger' });
        }
    }

    function selectAll() {
        for (let item of billsResponse.items) {
            item.selected = true;
        }
        setBillsResponse(Object.assign({}, billsResponse));
    }

    function deselectAll() {
        for (let item of billsResponse.items) {
            item.selected = false;
        }
        setBillsResponse(Object.assign({}, billsResponse));
    }

    function downloadRegisterExcel() {
        axios({
            method: 'GET',
            url: '/bill/excel/bills/' + billdate,
            headers: { 'X-SocietyId': selectedSociety },
            responseType: 'arraybuffer'
        })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'BillRegister.xlsx';
                link.click();
            });
    }

    function downloadRegisterPdf() {
        axios({
            method: 'GET',
            url: '/bill/pdf/bills/' + billdate,
            headers: { 'X-SocietyId': selectedSociety },
            responseType: 'arraybuffer'
        })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                window.open(window.URL.createObjectURL(blob));
            });
    }

    function downloadPdf() {
        let selected = [];
        for (let item of billsResponse.items) {
            if (item.selected) selected.push(item.billNo);
        }

        if (selected.length === 0) {
            alert("No bills selected");
            return;
        }

        axios({
            method: 'POST',
            url: '/bill/pdf',
            headers: { 'X-SocietyId': selectedSociety },
            data: JSON.stringify({ billDate: billdate, billNos: Array.from(selected) }),
            responseType: 'arraybuffer'
        })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                window.open(window.URL.createObjectURL(blob));
            });
    }

    return (
        <>
            <Typography variant="h5">Bills for {moment(billdate).format('DD/MM/YYYY')}</Typography>
            <div className={classes.topBar}>
                {window.decodedJwt.role === 'Administrator' &&
                    <Button variant="contained" size="small" color="primary" onClick={save}>Save</Button>
                }
                <Button onClick={downloadPdf} variant="contained" size="small" color="primary" className={classes.topBarControl}>PDF</Button>
                <Button onClick={downloadRegisterExcel} variant="contained" size="small" color="primary" className={classes.topBarControl}>Register (Excel)</Button>
                <Button onClick={downloadRegisterPdf} variant="contained" size="small" color="primary" className={classes.topBarControl}>Register (PDF)</Button>
                <Button onClick={deselectAll} variant="contained" size="small" color="primary" className={classes.topBarControl}>Deselect All</Button>
                <Button onClick={selectAll} variant="contained" size="small" color="primary" className={classes.topBarControl}>Select All</Button>
            </div>
            <form autoComplete="off" onKeyDown={(e) => { if (e.keyCode === 13) e.preventDefault(); }}>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Bill No</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell>Building</TableCell>
                                <TableCell>Wing</TableCell>
                                <TableCell>Flat</TableCell>
                                <TableCell>Name</TableCell>
                                {renderBillItemHeaders()}
                                <TableCell>Bill Principal</TableCell>
                                <TableCell>Bill Principal (Interest N/A)</TableCell>
                                <TableCell>Bill Interest</TableCell>
                                <TableCell>Bill Interest Override</TableCell>
                                <TableCell>Bill Amount</TableCell>
                                <TableCell>Arrears Principal</TableCell>
                                <TableCell>Arrears Principal (Interest N/A)</TableCell>
                                <TableCell>Arrears Interest</TableCell>
                                <TableCell>Arrears</TableCell>
                                <TableCell>Grand Total</TableCell>
                            </TableRow>
                        </TableHead>
                        {<TableBody>
                            {billsResponse.items.map((row, index) => (
                                <TableRow hover key={index}>
                                    <TableCell><Checkbox checked={row.selected} onChange={(e) => { row.selected = e.target.checked; setBillsResponse(Object.assign({}, billsResponse)); }} /></TableCell>
                                    <TableCell>{row.billNo}</TableCell>
                                    <TableCell>{row.memberAccountCode}</TableCell>
                                    <TableCell>{row.buildingNo}</TableCell>
                                    <TableCell>{row.wing}</TableCell>
                                    <TableCell>{row.unit}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    {renderBillItemAmountCells(row)}
                                    <TableCell>{row.billPrincipal}</TableCell>
                                    <TableCell>{row.billPrincipalNoInterest}</TableCell>
                                    <TableCell title={row.billInterestDetails}>{row.billInterest}</TableCell>
                                    <TableCell>
                                        <TextField type="number" className={classes.amount}
                                            id={'billInterestOverride_' + row.billNo}
                                            inputProps={{ code: row.memberAccountCode }}
                                            defaultValue={row.billInterestOverride}
                                            onChange={() => changedBillNos.add(row.billNo)}
                                            onKeyUp={(e) => {
                                                if (e.keyCode === 13) {
                                                    let form = e.target.form;
                                                    let index = Array.prototype.indexOf.call(form, e.target);
                                                    let nextElement = form.elements[index + ((billsResponse.billItems.length + 1) * 2) + 1];
                                                    if (nextElement) {
                                                        nextElement.focus();
                                                        nextElement.select();
                                                    }
                                                }
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>{row.billAmount}</TableCell>
                                    <TableCell>{row.arrearsPrincipal}</TableCell>
                                    <TableCell>{row.arrearsPrincipalNoInterest}</TableCell>
                                    <TableCell>{row.arrearsInterest}</TableCell>
                                    <TableCell>{row.arrears}</TableCell>
                                    <TableCell>{row.grandTotal}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>}
                    </Table>
                </TableContainer>
            </form>
        </>
    );
}

export default connect(mapToProps, actions)(BillsView);