import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Grid,
  Button,
  FormControlLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import { connect } from "redux-zero/react";
import actions from "../../actions";
import moment from "moment";
import { formatMoney } from "../../utils";

const useStyles = makeStyles((theme) => ({
  topBar: {
    marginBottom: 10,
  },
  topBarCheckBox: {
    marginRight: 30,
  },
  topBarLeftControl: {
    marginLeft: 10,
  },
  topBarControl: {
    float: "right",
    marginLeft: 10,
  },
}));

const mapToProps = ({ selectedSociety, currentPeriod }) => ({
  selectedSociety,
  currentPeriod,
});

function TrialBalanceReport({ selectedSociety, currentPeriod }) {
  const classes = useStyles();

  const [onlyWithBalance, setOnlyWithBalance] = useState(true);
  const [report, setReport] = useState({ items: [] });
  const [fromDate, setFromDate] = useState(currentPeriod.startDate);
  const [toDate, setToDate] = useState(currentPeriod.endDate);

  useEffect(() => {
    if (moment(fromDate).year() < 1000 || moment(toDate).year() < 1000) return;

    axios
      .get(
        "/report/trialbalance/" +
          (onlyWithBalance ? "nonzeroonly" : "all") +
          "/" +
          moment(fromDate).format("YYYY-MM-DD") +
          "/" +
          moment(toDate).format("YYYY-MM-DD"),
        { headers: { "X-SocietyId": selectedSociety } }
      )
      .then((response) => {
        setReport(response.data);
      });
  }, [selectedSociety, onlyWithBalance, fromDate, toDate]);

  function downloadPdf() {
    axios
      .get(
        "/report/pdf/trialbalance/" +
          (onlyWithBalance ? "nonzeroonly" : "all") +
          "/" +
          moment(fromDate).format("YYYY-MM-DD") +
          "/" +
          moment(toDate).format("YYYY-MM-DD"),
        {
          headers: { "X-SocietyId": selectedSociety },
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        window.open(window.URL.createObjectURL(blob));
      });
  }

  function downloadExcel() {
    axios
      .get(
        "/report/excel/trialbalance/" +
          (onlyWithBalance ? "nonzeroonly" : "all") +
          "/" +
          moment(fromDate).format("YYYY-MM-DD") +
          "/" +
          moment(toDate).format("YYYY-MM-DD"),
        {
          headers: { "X-SocietyId": selectedSociety },
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "TrialBalance.xlsx";
        link.click();
      });
  }

  return (
    <>
      <Typography variant="h5">Trial Balance</Typography>
      <div className={classes.topBar}>
        <Grid container>
          <Grid item xs={8}>
            <FormControlLabel
              label="Only show accounts with debit/credit balances"
              className={classes.topBarCheckBox}
              control={
                <Checkbox
                  checked={onlyWithBalance}
                  onChange={() => {
                    setOnlyWithBalance(!onlyWithBalance);
                  }}
                />
              }
            />
            <TextField
              className={classes.topBarLeftControl}
              type="date"
              value={fromDate}
              label="From"
              onChange={(e) => {
                setFromDate(e.target.value);
              }}
            />
            <TextField
              className={classes.topBarLeftControl}
              type="date"
              value={toDate}
              label="To"
              onChange={(e) => {
                setToDate(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              className={classes.topBarControl}
              onClick={downloadPdf}
              variant="contained"
              size="small"
              color="primary"
            >
              PDF
            </Button>
            <Button
              className={classes.topBarControl}
              onClick={downloadExcel}
              variant="contained"
              size="small"
              color="primary"
            >
              Excel
            </Button>
          </Grid>
        </Grid>
      </div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="right">Opening Balance</TableCell>
              <TableCell align="right">Debit</TableCell>
              <TableCell align="right">Credit</TableCell>
              <TableCell align="right">Closing Balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {report.items.map((row, index) => (
              <TableRow hover key={index}>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell align="right">
                  {(row.code + "").substr(0, 1) === "1" ||
                  (row.code + "").substr(0, 1) === "2"
                    ? formatMoney(row.openingBalance)
                    : ""}
                </TableCell>
                <TableCell align="right">
                  {row.debit ? formatMoney(row.debit) : ""}
                </TableCell>
                <TableCell align="right">
                  {row.credit ? formatMoney(row.credit) : ""}
                </TableCell>
                <TableCell align="right">
                  {formatMoney(row.closingBalance)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="right">
                <b>{formatMoney(report.totalDebit)}</b>
              </TableCell>
              <TableCell align="right">
                <b>{formatMoney(report.totalCredit)}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default connect(mapToProps, actions)(TrialBalanceReport);
