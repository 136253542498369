import React, { useState } from 'react';
import { Button, FormControl, Modal, makeStyles, MenuItem } from '@material-ui/core';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import SharpTextField from '../SharpTextField';
import SharpSelectField from '../SharpSelectField';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 500
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 800
    },
    formControlFullWidth: {
        margin: theme.spacing(1, 0),
        width: '100%',
    },
}));

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function AccountEdit({ selectedSociety, mode, model, onClose }) {
    const classes = useStyles();
    const [error, setError] = useState('');

    function onSubmit(value) {
        setError('');

        if (mode === 'Create') {
            axios({
                method: 'POST',
                url: '/account',
                headers: { 'X-SocietyId': selectedSociety },
                data: JSON.stringify(value)
            })
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    setError(error.response.data.message);
                });
        } else if (mode === 'Edit') {
            axios({
                method: 'PUT',
                url: '/account/' + model.id,
                headers: { 'X-SocietyId': selectedSociety },
                data: JSON.stringify(value)
            })
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    setError(error.response.data.message);
                });
        }
    }

    function typeMatchesCode(ref, msg) {
        return this.test({
            name: 'typeMatchesCode',
            exclusive: false,
            message: msg || 'Type and Code mismatch',
            params: {
                reference: ref.path
            },
            test: function (type) {
                var code = this.resolve(ref) + "";
                var c = code.substr(0, 1);

                switch (c) {
                    case "1":
                        return type === 'Liability' || type === 'Accounts Payable';
                    case "2":
                        return type === 'Asset' || type === 'Accounts Receivable' || type === 'Bank' || type === 'Cash' || type === 'Depreciating Asset';
                    case "3":
                        return type === 'Income';
                    case "4":
                        return type === 'Expense';
                    default:
                        return false;
                }
            }
        })
    };

    Yup.addMethod(Yup.string, 'typeMatchesCode', typeMatchesCode);

    return (
        <Modal className={classes.modal} open={(model != null)} onClose={onClose}>
            <div className={classes.paper}>
                <h2 id="transition-modal-title">{mode} Account</h2>
                <Formik initialValues={model}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        code: Yup.number().required('Required').min(1000000).max(9990000),
                        name: Yup.string().required('Required'),
                        type: Yup.string().required('Required').typeMatchesCode(Yup.ref('code'))
                    })}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {({ isValid }) => (
                        <Form autoComplete="off" onKeyDown={(e) => { if (e.keyCode === 13 && e.target.id !== 'save') e.preventDefault(); }}>
                            <div>
                                <FormControl className={classes.formControlFullWidth}>
                                    <SharpTextField
                                        id="code"
                                        name="code"
                                        label="Code"
                                        type="number"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        disabled={model.isSystem}
                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('name').focus(); } }}
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl className={classes.formControlFullWidth}>
                                    <SharpTextField
                                        id="name"
                                        name="name"
                                        label="Name"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('type').focus(); } }}
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl className={classes.formControlFullWidth}>
                                    <SharpSelectField
                                        id="type"
                                        name="type"
                                        label="Type"
                                        fullWidth
                                        disabled={model.isSystem}
                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('save').focus(); } }}
                                    >
                                        <MenuItem value={'Asset'}>Asset</MenuItem>
                                        <MenuItem value={'Liability'}>Liability</MenuItem>
                                        <MenuItem value={'Income'}>Income</MenuItem>
                                        <MenuItem value={'Expense'}>Expense</MenuItem>
                                        <MenuItem value={'Accounts Receivable'}>Accounts Receivable</MenuItem>
                                        <MenuItem value={'Accounts Payable'}>Accounts Payable</MenuItem>
                                        <MenuItem value={'Bank'}>Bank</MenuItem>
                                        <MenuItem value={'Cash'}>Cash</MenuItem>
                                        <MenuItem value={'Depreciating Asset'}>Depreciating Asset</MenuItem>
                                    </SharpSelectField>
                                </FormControl>
                            </div>
                            <div>
                                {error}
                            </div>
                            <div>
                                <Button id="save" variant="contained" color="primary" type="submit" disabled={!isValid}>
                                    Save
                                </Button>
                                <Button variant="contained" color="secondary" type="submit" onClick={() => { setError(''); onClose(); }}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
}

export default connect(mapToProps, actions)(AccountEdit);