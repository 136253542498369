import React, { useState, useEffect } from 'react';
import { Typography, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TextField, Button, Grid, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Calendar from 'react-calendar'
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import moment from 'moment';
import { formatMoney } from '../../utils';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginTop: 5,
        marginBottom: 10
    },
    topBarControl: {
        float: 'right',
        marginTop: 6
    },
    topBarLeftControl: {
        marginRight: 5,
        marginBottom: 10
    }
}));

const mapToProps = ({ selectedSociety, currentPeriod }) => ({ selectedSociety, currentPeriod });

function BankReconciliation({ selectedSociety, currentPeriod }) {
    const classes = useStyles();

    const [accounts, setAccounts] = useState([]);
    const [accountCode, setAccountCode] = useState('');
    const [report, setReport] = useState({ items: [] });
    const [fromDate] = useState(currentPeriod.startDate);
    const [toDate, setToDate] = useState(currentPeriod.endDate);

    useEffect(() => {
        axios.get('/account/selectitems/bank', { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setAccounts(response.data);
            });
    }, [selectedSociety]);

    useEffect(() => {
        if (moment(fromDate).year() < 1000 || moment(toDate).year() < 1000) return;

        if (accountCode) {
            axios.get('/report/ledger/' + accountCode + '/' + moment(fromDate).format('YYYY-MM-DD') + '/' + moment(toDate).format('YYYY-MM-DD'),
                { headers: { 'X-SocietyId': selectedSociety } })
                .then(response => {
                    setReport(response.data);
                });
        }
    }, [selectedSociety, accountCode, fromDate, toDate]);

    return (
        <>
            <Typography variant="h5">Bank Reconciliation</Typography>
            <div className={classes.topBar}>
                <Grid container>
                    <Grid item xs={3}>
                        <Autocomplete
                            className={classes.topBarLeftControl}
                            options={accounts}
                            getOptionLabel={option => option.title}
                            selectOnFocus
                            disableOpenOnFocus
                            size="small"
                            renderInput={params => <TextField {...params} InputLabelProps={{ shrink: true }} label="Bank Account" />}
                            onChange={(_, value) => { if (value) { setAccountCode(value.code); } }}
                        />
                        <TextField className={classes.topBarLeftControl}
                            type="date"
                            value={toDate}
                            label="Date"
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e) => { setToDate(e.target.value) }} />
                        <TextField className={classes.topBarLeftControl}
                            type="number"
                            value={toDate}
                            label="Closing Balance"
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e) => { setToDate(e.target.value) }} />
                        <TextField className={classes.topBarLeftControl}
                            type="number"
                            value={toDate}
                            label="Reconciled Balance"
                            disabled
                            InputLabelProps={{
                                shrink: true
                            }}
                            onChange={(e) => { setToDate(e.target.value) }} />
                        <div>
                            <Button className={classes.topBarLeftControl}
                                variant="contained"
                                color="primary">Save Cleared</Button>
                            <Button className={classes.topBarLeftControl}
                                variant="contained"
                                color="primary">Mark as Reconciled</Button>
                        </div>
                        <div>
                            <label><b>Cleared Date:</b></label>
                            <Calendar
                                value={new Date()}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={9}>
                        <p>Table goes here</p>
                    </Grid>
                </Grid>
            </div>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Particulars</TableCell>
                            <TableCell align="right">Debit</TableCell>
                            <TableCell align="right">Credit</TableCell>
                            <TableCell align="right">Closing Balance</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {report.items.map((row, index) => (
                            <TableRow hover key={index}>
                                <TableCell>{moment(row.date).format('DD/MM/YYYY')}</TableCell>
                                <TableCell>
                                    <div><b>{row.name}</b></div>
                                    <div>{row.summary}</div>
                                    {row.narration && <div>{row.narration}</div>}
                                </TableCell>
                                <TableCell align="right">{row.debit ? formatMoney(row.debit) : ''}</TableCell>
                                <TableCell align="right">{row.credit ? formatMoney(row.credit) : ''}</TableCell>
                                <TableCell align="right">{formatMoney(row.closingBalance)}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right"><b>Total</b></TableCell>
                            <TableCell align="right"><b>{formatMoney(report.totalDebit)}</b></TableCell>
                            <TableCell align="right"><b>{formatMoney(report.totalCredit)}</b></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default connect(mapToProps, actions)(BankReconciliation);