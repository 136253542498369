import React, { useState, useEffect } from 'react';
import { Typography, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TextField, Button, Grid, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import moment from 'moment';
import { formatMoney } from '../../utils';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginBottom: 10
    },
    topBarControl: {
        float: 'right',
        marginTop: 6
    }
}));

const mapToProps = ({ selectedSociety, currentPeriod }) => ({ selectedSociety, currentPeriod });

function LedgerReport({ selectedSociety, currentPeriod }) {
    const classes = useStyles();

    const [accounts, setAccounts] = useState([]);
    const [accountCode, setAccountCode] = useState('');
    const [report, setReport] = useState({ items: [] });
    const [fromDate, setFromDate] = useState(currentPeriod.startDate);
    const [toDate, setToDate] = useState(currentPeriod.endDate);

    useEffect(() => {
        axios.get('/account/selectitems/all', { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setAccounts(response.data);
            });
    }, [selectedSociety]);

    useEffect(() => {
        if (moment(fromDate).year() < 1000 || moment(toDate).year() < 1000) return;
        
        if (accountCode) {
            axios.get('/report/ledger/' + accountCode + '/' + moment(fromDate).format('YYYY-MM-DD') + '/' + moment(toDate).format('YYYY-MM-DD'),
                        { headers: { 'X-SocietyId': selectedSociety } })
                .then(response => {
                    setReport(response.data);
                });
        }
    }, [selectedSociety, accountCode, fromDate, toDate]);

    function downloadPdf() {
        axios.get('/report/pdf/ledger/' + accountCode + '/' + moment(fromDate).format('YYYY-MM-DD') + '/' + moment(toDate).format('YYYY-MM-DD'),
                    { headers: { 'X-SocietyId': selectedSociety }, responseType: 'arraybuffer' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                window.open(window.URL.createObjectURL(blob));
            });
    }

    return (
        <>
            <Typography variant="h5">Ledger</Typography>
            <div className={classes.topBar}>
                <Grid container>
                    <Grid item xs={6}>
                        <Autocomplete
                            options={accounts}
                            getOptionLabel={option => option.title}
                            selectOnFocus
                            disableOpenOnFocus
                            size="small"
                            renderInput={params => <TextField {...params} InputLabelProps={{ shrink: true }} label="Account" />}
                            onChange={(_, value) => { if (value) { setAccountCode(value.code); } }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField className={classes.topBarLeftControl}
                            type="date"
                            value={fromDate}
                            label="From"
                            onChange={(e) => { setFromDate(e.target.value) }} />
                        <TextField className={classes.topBarLeftControl}
                            type="date"
                            value={toDate}
                            label="To"
                            onChange={(e) => { setToDate(e.target.value) }} />
                        <Button className={classes.topBarControl} onClick={downloadPdf} variant="contained" size="small" color="primary" disabled={!accountCode}>PDF</Button>
                    </Grid>
                </Grid>
            </div>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Particulars</TableCell>
                            <TableCell align="right">Debit</TableCell>
                            <TableCell align="right">Credit</TableCell>
                            <TableCell align="right">Closing Balance</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {report.items.map((row, index) => (
                            <TableRow hover key={index}>
                                <TableCell>{moment(row.date).format('DD/MM/YYYY')}</TableCell>
                                <TableCell>
                                    <div><b>{row.name}</b></div>
                                    <div>{row.summary}</div>
                                    {row.narration && <div>{row.narration}</div>}
                                </TableCell>
                                <TableCell align="right">{row.debit ? formatMoney(row.debit) : ''}</TableCell>
                                <TableCell align="right">{row.credit ? formatMoney(row.credit) : ''}</TableCell>
                                <TableCell align="right">{formatMoney(row.closingBalance)}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right"><b>Total</b></TableCell>
                            <TableCell align="right"><b>{formatMoney(report.totalDebit)}</b></TableCell>
                            <TableCell align="right"><b>{formatMoney(report.totalCredit)}</b></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default connect(mapToProps, actions)(LedgerReport);