import React, { useState } from 'react';
import { Button, FormControl, MenuItem, Modal, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import SharpTextField from '../SharpTextField';
import SharpSelectField from '../SharpSelectField';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 500
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 800
    },
    formControlFullWidth: {
        margin: theme.spacing(1, 0),
        width: '100%',
    },
}));

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function BillItemEdit({ selectedSociety, mode, model, onClose }) {
    const classes = useStyles();
    const [error, setError] = useState('');

    function onSubmit(value) {
        setError('');

        value.isBillItem = true;
        if (mode === 'Create') {
            axios({
                method: 'POST',
                url: '/billitem',
                headers: { 'X-SocietyId': selectedSociety },
                data: JSON.stringify(value)
            })
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    setError(error.response.data.message);
                });
        } else if (mode === 'Edit') {
            axios({
                method: 'PUT',
                url: '/billitem/' + model.id,
                headers: { 'X-SocietyId': selectedSociety },
                data: JSON.stringify(value)
            })
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    setError(error.response.data.message);
                });
        }
    }

    function typeMatchesCode(ref, msg) {
        return this.test({
            name: 'typeMatchesCode',
            exclusive: false,
            message: msg || 'Type and Code mismatch',
            params: {
                reference: ref.path
            },
            test: function (type) {
                var code = this.resolve(ref) + '';
                var c = code.substr(0, 1);

                switch (c) {
                    case '1':
                        return type === 'Liability' || type === 'AccountsPayable';
                    case '2':
                        return type === 'Asset' || type === 'AccountsReceivable';
                    case '3':
                        return type === 'Income';
                    case '4':
                        return type === 'Expense';
                    default:
                        return false;
                }
            }
        })
    };

    Yup.addMethod(Yup.string, 'typeMatchesCode', typeMatchesCode);

    return (
        <Modal className={classes.modal} open={(model != null)} onClose={onClose}>
            <div className={classes.paper}>
                <h2 id="transition-modal-title">{mode} Bill Item</h2>
                <Formik initialValues={model}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        code: Yup.number().required('Required').min(1000000).max(9990000),
                        type: Yup.string().required('Required').typeMatchesCode(Yup.ref('code')),
                        name: Yup.string().required('Required'),
                        chargeInterest: Yup.boolean().required('Required'),
                        recurrence: Yup.string().required('Required'),
                        highlight: Yup.boolean().required('Required')
                    })}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {({ isValid }) => (
                        <Form autoComplete="off" onKeyDown={(e) => { if (e.keyCode === 13 && e.target.id !== 'save') e.preventDefault(); }}>
                            <div>
                                <FormControl className={classes.formControlFullWidth}>
                                    <SharpTextField
                                        id="code"
                                        name="code"
                                        label="Code"
                                        type="number"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('type').focus(); } }}
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl className={classes.formControlFullWidth}>
                                    <SharpSelectField
                                        id="type"
                                        name="type"
                                        label="Type"
                                        fullWidth
                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('name').focus(); } }}
                                    >
                                        <MenuItem value={'Asset'}>Asset</MenuItem>
                                        <MenuItem value={'Liability'}>Liability</MenuItem>
                                        <MenuItem value={'Income'}>Income</MenuItem>
                                        <MenuItem value={'Expense'}>Expense</MenuItem>
                                        <MenuItem value={'AccountsReceivable'}>Accounts Receivable</MenuItem>
                                        <MenuItem value={'AccountsPayable'}>Accounts Payable</MenuItem>
                                    </SharpSelectField>
                                </FormControl>
                            </div>
                            <div>
                                <FormControl className={classes.formControlFullWidth}>
                                    <SharpTextField
                                        id="name"
                                        name="name"
                                        label="Name"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('recurrence').focus(); } }}
                                    />
                                </FormControl>
                            </div>
                            <div>
                                <FormControl className={classes.formControlFullWidth}>
                                    <SharpSelectField
                                        id="recurrence"
                                        name="recurrence"
                                        label="Recurrence"
                                        fullWidth
                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('chargeInterest').focus(); } }}
                                    >
                                        <MenuItem value="Every bill">Every bill</MenuItem>
                                        <MenuItem value="One time">One time</MenuItem>
                                        {/* <MenuItem value="Specific bills">Specific bills</MenuItem> */}
                                    </SharpSelectField>
                                </FormControl>
                            </div>
                            <div>
                                <FormControl className={classes.formControlFullWidth}>
                                    <SharpSelectField
                                        id="chargeInterest"
                                        name="chargeInterest"
                                        label="Charge Interest"
                                        fullWidth
                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('highlight').focus(); } }}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </SharpSelectField>
                                </FormControl>
                            </div>
                            <div>
                                <FormControl className={classes.formControlFullWidth}>
                                    <SharpSelectField
                                        id="highlight"
                                        name="highlight"
                                        label="Highlight"
                                        fullWidth
                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('save').focus(); } }}
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </SharpSelectField>
                                </FormControl>
                            </div>
                            <div>
                                {error}
                            </div>
                            <div>
                                <Button id="save" variant="contained" color="primary" type="submit" disabled={!isValid}>
                                    Save
                                </Button>
                                <Button variant="contained" color="secondary" type="submit" onClick={() => { setError(''); onClose(); }}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
}

export default connect(mapToProps, actions)(BillItemEdit);