import React from 'react';
import { Button, Modal, makeStyles } from '@material-ui/core';
import { connect } from 'redux-zero/react';
import actions from '../actions';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 500
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 800
    },
    formControlFullWidth: {
        margin: theme.spacing(1),
        width: '100%',
    },
}));

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function ConfirmDialog({ model, show, title, message, onYes, onClose }) {
    const classes = useStyles();

    return (
        <Modal className={classes.modal} open={(show)} onClose={onClose}>
            <div className={classes.paper}>
                <h2 id="transition-modal-title">{title}</h2>
                <p>{message}</p>
                <Button variant="contained" color="primary" type="submit" onClick={() => onYes(model)}>Yes</Button>
                <Button variant="contained" color="secondary" type="submit" onClick={onClose}>Cancel</Button>
            </div>
        </Modal>
    );
}

export default connect(mapToProps, actions)(ConfirmDialog);