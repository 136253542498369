const actions = store => ({
  setIsAuthenticated: (store, value) => {
    return { isAuthenticated: value };
  },
  setSelectedSociety: (store, value) => {
    localStorage.setItem('selectedSociety', value);
    return { selectedSociety: value };
  },
  setSelectedSocietyName: (store, value) => {
    localStorage.setItem('selectedSocietyName', value);
    return { selectedSocietyName: value };
  },
  setCurrentPeriod: (store, value) => {
    localStorage.setItem('currentPeriod', JSON.stringify(value));
    return { currentPeriod: value };
  },
  setSocietyDetailsFormType: (store, value) => ({ societyDetailsFormType: value })
})

export default actions;