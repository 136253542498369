import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
import { connect } from "redux-zero/react";
import actions from "../../actions";
import moment from "moment";
import { formatMoney } from "../../utils";

const useStyles = makeStyles((theme) => ({
  topBar: {
    marginBottom: 10,
  },
  topBarControl: {
    float: "right",
    marginTop: 6,
  },
}));

const mapToProps = ({ selectedSociety, currentPeriod }) => ({
  selectedSociety,
  currentPeriod,
});

function BillContributionsReport({ selectedSociety, currentPeriod }) {
  const classes = useStyles();

  const [accounts, setAccounts] = useState([]);
  const [accountCode, setAccountCode] = useState("");
  const [report, setReport] = useState({ headers: [], items: [] });
  const [fromDate, setFromDate] = useState(currentPeriod.startDate);
  const [toDate, setToDate] = useState(currentPeriod.endDate);

  useEffect(() => {
    axios
      .get("/account/selectitems/billitem", {
        headers: { "X-SocietyId": selectedSociety },
      })
      .then((response) => {
        setAccounts(response.data);
      });
  }, [selectedSociety]);

  useEffect(() => {
    if (moment(fromDate).year() < 1000 || moment(toDate).year() < 1000) return;

    if (accountCode) {
      axios
        .get(
          "/report/billcontributions/" +
            accountCode +
            "/" +
            moment(fromDate).format("YYYY-MM-DD") +
            "/" +
            moment(toDate).format("YYYY-MM-DD"),
          { headers: { "X-SocietyId": selectedSociety } }
        )
        .then((response) => {
          setReport(response.data);
        });
    }
  }, [selectedSociety, accountCode, fromDate, toDate]);

  function downloadPdf() {
    axios
      .get(
        "/report/pdf/billcontributions/" +
          accountCode +
          "/" +
          moment(fromDate).format("YYYY-MM-DD") +
          "/" +
          moment(toDate).format("YYYY-MM-DD"),
        {
          headers: { "X-SocietyId": selectedSociety },
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        window.open(window.URL.createObjectURL(blob));
      });
  }

  return (
    <>
      <Typography variant="h5">Bill Contributions</Typography>
      <div className={classes.topBar}>
        <Grid container>
          <Grid item xs={6}>
            <Autocomplete
              options={accounts}
              getOptionLabel={(option) => option.title}
              selectOnFocus
              disableOpenOnFocus
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  label="Account"
                />
              )}
              onChange={(_, value) => {
                if (value) {
                  setAccountCode(value.code);
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.topBarLeftControl}
              type="date"
              value={fromDate}
              label="From"
              onChange={(e) => {
                setFromDate(e.target.value);
              }}
            />
            <TextField
              className={classes.topBarLeftControl}
              type="date"
              value={toDate}
              label="To"
              onChange={(e) => {
                setToDate(e.target.value);
              }}
            />
            <Button
              className={classes.topBarControl}
              onClick={downloadPdf}
              variant="contained"
              size="small"
              color="primary"
              disabled={!accountCode}
            >
              PDF
            </Button>
          </Grid>
        </Grid>
      </div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Flat No.</TableCell>
              {report.headers.map((header, index) => (
                <TableCell key={index} align="right">
                  {header}
                </TableCell>
              ))}
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {report.items.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.flatNo}</TableCell>
                {row.amounts.map((amount) => (
                  <TableCell align="right">{formatMoney(amount)}</TableCell>
                ))}
                <TableCell align="right">{formatMoney(row.total)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default connect(mapToProps, actions)(BillContributionsReport);
