import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Typography, Button, TextField, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginBottom: 10
    },
    red: {
        color: 'red'
    },
    totals: {
        fontSize: 16,
        fontWeight: 'bold',
        marginLeft: 140
    },
    total: {
        marginRight: 16
    }
}));

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function OpeningBalances({ selectedSociety }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [refreshData, setRefreshData] = React.useState(1);
    const [accounts, setAccounts] = useState([]);
    const [assetsOpeningBalance, setAssetsOpeningBalance] = useState(0.0);
    const [liabilitiesOpeningBalance, setLiabilitiesOpeningBalance] = useState(0.0);
    const [changedCodes] = useState(new Set());

    useEffect(() => {
        axios.get('/account/openingbalance/list', { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setAccounts(response.data);
                changedCodes.clear();
            });
    },
        // eslint-disable-next-line
        [selectedSociety, refreshData]);

    useEffect(() => {
        updateBalances();
    },
        // eslint-disable-next-line
        [accounts]);

    function focusNext(e) {
        if (e.keyCode === 13) {
            const inputs = document.querySelectorAll('input');
            let i = 0;
            for (let input of inputs) {
                if (e.target === input) {
                    const nextInput = inputs[i + 1];
                    if (nextInput) {
                        nextInput.focus();
                        nextInput.select();
                    }
                }
                i++;
            }
        }
    }

    function updateBalances() {
        let assets = 0.0;
        let liabilities = 0.0;

        for (let account of accounts) {
            let type = (account.code + '').substr(0, 1);

            let openingBalance = parseFloat(document.getElementById(`openingbalance_${account.code}`).value);

            if (type === '1') {
                liabilities += openingBalance;
            } else if (type === '2') {
                assets += openingBalance;
            }
        }

        setAssetsOpeningBalance(assets);
        setLiabilitiesOpeningBalance(liabilities);
    }

    function save() {
        let changedRows = [];

        for (let code of changedCodes) {
            let openingBalance = parseFloat(document.getElementById('openingbalance_' + code).value);
            let openingInterest = 0.0;
            let openingInterestTextBox = document.getElementById('openinginterest_' + code);
            if (openingInterestTextBox) {
                openingInterest = parseFloat(openingInterestTextBox.value);
            }

            changedRows.push({
                code: code,
                openingBalance: openingBalance,
                openingInterest: openingInterest
            });
        }

        axios({
            method: 'PUT',
            url: '/account/openingbalance',
            headers: { 'X-SocietyId': selectedSociety },
            data: JSON.stringify(changedRows)
        })
            .then(response => {
                setRefreshData(refreshData + 1);
                enqueueSnackbar('Saved', { variant: 'success' });
            })
            .catch(error => {
                alert(error.response.data.message);
            });
    }

    return (
        <>
            <Typography variant="h5">Opening Balances</Typography>
            <div className={classes.topBar}>
                {window.decodedJwt.role === 'Administrator' &&
                    <Button variant="contained" size="small" color="primary" onClick={save}>Save</Button>
                }
                <span className={classes.totals}>
                    <span className={classes.total}>Total Assets: {assetsOpeningBalance}</span>
                    <span className={classes.total}>Total Liabilities: {liabilitiesOpeningBalance}</span>
                    <span className={assetsOpeningBalance - liabilitiesOpeningBalance !== 0.0 ? 'error' : ''}>
                        Difference: {assetsOpeningBalance - liabilitiesOpeningBalance}
                    </span>
                </span>
            </div>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Bill Item</TableCell>
                            <TableCell>O/Balance</TableCell>
                            <TableCell>O/Interest</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accounts.map((row, index) => (
                            <TableRow hover key={index}>
                                <TableCell>{row.code}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.type}</TableCell>
                                <TableCell>{row.isBillItem ? 'Yes' : 'No'}</TableCell>
                                <TableCell>
                                    <TextField type="number"
                                        id={'openingbalance_' + row.code}
                                        defaultValue={row.openingBalance}
                                        onBlur={() => { changedCodes.add(row.code); updateBalances(); console.log(changedCodes) }}
                                        onKeyUp={focusNext} />
                                </TableCell>
                                <TableCell>
                                    {row.type === "Member" && (
                                        <TextField type="number"
                                            id={'openinginterest_' + row.code}
                                            defaultValue={row.openingInterest}
                                            onBlur={() => { changedCodes.add(row.code); updateBalances(); }}
                                            onKeyUp={focusNext} />
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default connect(mapToProps, actions)(OpeningBalances);