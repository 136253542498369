import React, { useState, useEffect } from 'react';
import { Typography, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Checkbox, Button, FormControlLabel, TextField, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import moment from 'moment';
import { formatMoney } from '../../utils';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginBottom: 10
    },
    topBarCheckBox: {
        marginRight: 30
    },
    topBarLeftControl: {
        marginLeft: 10
    },
    topBarControl: {
        float: 'right',
        marginLeft: 10
    }
}));

const mapToProps = ({ selectedSociety, currentPeriod }) => ({ selectedSociety, currentPeriod });

function MemberBalanceReport({ selectedSociety, currentPeriod }) {
    const classes = useStyles();

    const [outstandingOnly, setOutstandingOnly] = useState(true);
    const [report, setReport] = useState({ items: [] });
    const [fromDate, setFromDate] = useState(currentPeriod.startDate);
    const [toDate, setToDate] = useState(currentPeriod.endDate);

    useEffect(() => {
        if (moment(fromDate).year() < 1000 || moment(toDate).year() < 1000) return;
        
        axios.get('/report/memberbalance/' + (outstandingOnly ? 'outstanding' : 'all') + '/' + moment(fromDate).format('YYYY-MM-DD') + '/' + moment(toDate).format('YYYY-MM-DD'),
            { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setReport(response.data);
            });
    }, [selectedSociety, outstandingOnly, fromDate, toDate]);

    function downloadPdf() {
        axios({
            method: 'GET',
            url: '/report/pdf/memberbalance/' + (outstandingOnly ? 'outstanding' : 'all') + '/' + moment(fromDate).format('YYYY-MM-DD') + '/' + moment(toDate).format('YYYY-MM-DD'),
            headers: { 'X-SocietyId': selectedSociety },
            responseType: 'arraybuffer'
        })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                window.open(window.URL.createObjectURL(blob));
            });
    }

    function sendSms() {
        axios({
            method: 'POST',
            url: '/report/memberbalancesendsms',
            headers: { 'X-SocietyId': selectedSociety },
            data: JSON.stringify({
                startDate: moment(fromDate).format('YYYY-MM-DD'),
                endDate: moment(toDate).format('YYYY-MM-DD')
            })
        })
            .then(response => {
                alert(response.data);
            });
    }

    return (
        <>
            <Typography variant="h5">Member Balance</Typography>
            <div className={classes.topBar}>
                <FormControlLabel label="Display outstanding only" className={classes.topBarCheckBox}
                    control={<Checkbox checked={outstandingOnly}
                        onChange={() => { setOutstandingOnly(!outstandingOnly) }} />} />
                <TextField className={classes.topBarLeftControl}
                    type="date"
                    value={fromDate}
                    label="From"
                    onChange={(e) => { setFromDate(e.target.value) }} />
                <TextField className={classes.topBarLeftControl}
                    type="date"
                    value={toDate}
                    label="To"
                    onChange={(e) => { setToDate(e.target.value) }} />
                <Button onClick={downloadPdf} variant="contained" size="small" color="primary" className={classes.topBarControl}>PDF</Button>
                <Button onClick={sendSms} variant="contained" size="small" color="primary" className={classes.topBarControl}>Send SMS</Button>
            </div>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Building No</TableCell>
                            <TableCell>Wing</TableCell>
                            <TableCell>Unit</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Balance</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {report.items.map((row, index) => (
                            <TableRow hover key={index}>
                                <TableCell>{row.code}</TableCell>
                                <TableCell>{row.buildingNo}</TableCell>
                                <TableCell>{row.wing}</TableCell>
                                <TableCell>{row.unit}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell align="right">{formatMoney(row.closingBalance)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default connect(mapToProps, actions)(MemberBalanceReport);