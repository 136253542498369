import React from 'react';
import { Typography, Tabs, Tab, Box } from '@material-ui/core';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import BankReceipts from './BankReceipts';
import BankPayments from './BankPayments';
import CashReceipts from './CashReceipts';
import CashPayments from './CashPayments';
import JournalVouchers from './JournalVouchers';

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function Transactions({ selectedSociety, setSelectedSociety }) {
    const [value, setValue] = React.useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    return (
        <>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Bank Receipts" />
                <Tab label="Bank Payments" />
                <Tab label="Cash Receipts" />
                <Tab label="Cash Payments" />
                <Tab label="Journal Vouchers" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <BankReceipts />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <BankPayments />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <CashReceipts />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <CashPayments />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <JournalVouchers />
            </TabPanel>
        </>
    );
}

export default connect(mapToProps, actions)(Transactions);