import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Button, FormControl, TableCell, TableContainer, Paper, Table, TableHead, TableBody, TableRow, TextField, Grid, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Edit as EditIcon, Delete as DeleteIcon, Star as StarIcon } from '@material-ui/icons';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import moment from 'moment';
import ConfirmDialog from '../ConfirmDialog';
import { formatMoney } from '../../utils';

const useStyles = makeStyles(theme => ({
    actionCol: {
        width: 80
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 500
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 800
    },
    formControl: {
        margin: theme.spacing(1),
        width: '49%',
    },
    formControlFullWidth: {
        margin: theme.spacing(1, 0),
        width: '100%',
    },
    actionButton: {
        cursor: 'pointer'
    },
    formControlXAccount: {
        margin: theme.spacing(1),
        width: '90%'
    },
    makePrimaryAccount: {
        marginTop: 10,
        marginLeft: 10
    },
    closingBalance: {
        display: 'block',
        padding: 15,
    }
}));

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function CashPayments({ selectedSociety }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const blankFormValue = {
        type: 'CP',
        date: moment().format('YYYY-MM-DD'),
        xCode: { code: '', title: '' },
        yCode: { code: '', title: '' },
        amount: '',
        dc: 'DR',
        narration: ''
    };

    const [recordAdded, setRecordAdded] = useState(1);  // Record Added OR Record Edit
    const [errors, setErrors] = useState([]);
    const [mode, setMode] = useState('Create');
    const [model, setModel] = useState(Object.assign({}, blankFormValue));
    const [yCode, setYCode] = useState('');
    const [yBalance, setYBalance] = useState('');
    const [cashAccounts, setCashAccounts] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [narrations, setNarrations] = useState([]);

    const [transactions, setTransactions] = useState([]);
    const [transactionsLimit, setTransactionsLimit] = useState(5);
    const [refreshTransactions, setRefreshTransactions] = useState(1);

    const [deleteModel, setDeleteModel] = React.useState();
    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

    useEffect(() => {
        axios.get('/setting/primarycashaccount', { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                let primaryAccountCode = response.data.value;

                axios.get('/account/selectitems/cash', { headers: { 'X-SocietyId': selectedSociety } })
                    .then(response => {
                        setCashAccounts(response.data);

                        for (let cashAccount of response.data) {
                            if (cashAccount.code === primaryAccountCode) {
                                blankFormValue.xCode = cashAccount;
                                setModel(Object.assign({}, blankFormValue));
                                document.getElementById('yCode').focus();
                            }
                        }
                    });
            });

        axios.get('/account/selectitems/all', { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setAccounts(response.data);
            });
    },
        // eslint-disable-next-line
        [selectedSociety]);

    useEffect(() => {
        axios.get('/narration/list', { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setNarrations(response.data);
            });
    }, [selectedSociety, recordAdded]);

    useEffect(() => {
        axios.get('/transaction/list/CP/desc/' + transactionsLimit, { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setTransactions(response.data);
            });
    }, [selectedSociety, recordAdded, transactionsLimit, refreshTransactions]);

    useEffect(() => {
        axios.get(`/account/closingbalance/${yCode}/${moment().format('YYYY-MM-DD')}`, { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                let balance = response.data.balance;
                setYBalance(formatMoney(balance));
            });
    }, [selectedSociety, yCode]);

    function loadMore() {
        setTransactionsLimit(transactionsLimit + 10);
    }

    function edit(row) {
        let newErrors = [];
        setErrors(newErrors);

        const value = Object.assign({}, row);
        value.date = moment(row.date).format('YYYY-MM-DD');
        value.xCode = { code: row.xCode, title: row.xName };
        value.yCode = { code: row.yCode, title: row.yName };
        setModel(value);
        setYBalance('');
        setRecordAdded(recordAdded + 1);
        setMode('Edit');
        setTimeout(() => document.getElementById('yCode').focus(), 200);
    }

    function ddelete(row) {
        setDeleteModel(row);
        setShowConfirmDelete(true);
    }

    function save() {
        // If you enter new value in freeSolo Autocomplete and don't press enter, the value is not added to the model
        // If you assign the value to the model on keyUp or any other event the filtering does not work correctly
        // Hence, the below workaround
        model.narration = document.getElementById('narration').value;

        let newErrors = [];
        if (!model.date) newErrors.push('Date is required');
        if (isNaN(model.xCode.code) || model.xCode.code === '') newErrors.push('Cash Account is required');
        if (isNaN(model.yCode.code) || model.yCode.code === '') newErrors.push('Account is required');
        if (isNaN(model.amount) || model.amount === '' || model.amount === 0) newErrors.push('Amount is required and should be greater than zero');

        setErrors(newErrors);

        if (newErrors.length > 0) return;

        const value = Object.assign({}, model);
        value.xCode = model.xCode.code;
        value.yCode = model.yCode.code;
        if (!value.narration) value.narration = null;

        if (mode === 'Create') {
            axios({
                method: 'POST',
                url: '/transaction',
                headers: { 'X-SocietyId': selectedSociety },
                data: JSON.stringify(value)
            })
                .then(response => {
                    blankFormValue.date = model.date;
                    blankFormValue.xCode = model.xCode;

                    setModel(Object.assign({}, blankFormValue));
                    setRecordAdded(recordAdded + 1);

                    enqueueSnackbar('Saved', { variant: 'success' });
                    setTimeout(() => document.getElementById('yCode').focus(), 200);
                })
                .catch(error => {
                    let newErrors = ['Error: ' + error.response.data.message];
                    setErrors(newErrors);
                });
        } else {
            axios({
                method: 'PUT',
                url: '/transaction/' + model.id,
                headers: { 'X-SocietyId': selectedSociety },
                data: JSON.stringify(value)
            })
                .then(response => {
                    blankFormValue.date = model.date;
                    blankFormValue.xCode = model.xCode;

                    setModel(Object.assign({}, blankFormValue));
                    setRecordAdded(recordAdded + 1);
                    setMode('Create');

                    enqueueSnackbar('Saved', { variant: 'success' });
                    setTimeout(() => document.getElementById('yCode').focus(), 200);
                })
                .catch(error => {
                    let newErrors = ['Error: ' + error.response.data.message];
                    setErrors(newErrors);
                });
        }
    }

    function closeConfirmDeleteDialog() {
        setShowConfirmDelete(false);
    }

    function deleteTransaction(row) {
        closeConfirmDeleteDialog();
        axios({
            method: 'DELETE',
            url: '/transaction/' + row.id,
            headers: { 'X-SocietyId': selectedSociety }
        })
            .then(response => {
                setRefreshTransactions(refreshTransactions + 1);
            });
    }

    function makePrimaryAccount() {
        if (model.xCode && model.xCode.code && window.confirm(`Do you want to make ${model.xCode.title} the primary cash account?`)) {
            axios({
                method: 'PUT',
                url: '/setting/primarycashaccount',
                headers: { 'X-SocietyId': selectedSociety },
                data: JSON.stringify({ value: model.xCode.code })
            })
                .then(response => {
                });
        }
    }

    return (
        <>
            <form key={recordAdded} autoComplete="off" onKeyDown={(e) => { if (e.keyCode === 13 && e.target.id !== 'save') e.preventDefault(); }}>
                <Grid container>
                    <Grid item xs={6}>
                        <div>
                            <FormControl className={classes.formControlFullWidth}>
                                <TextField
                                    type="date"
                                    name="date"
                                    label="Date"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    value={model.date}
                                    onChange={(e) => { setModel({ ...model, date: moment(e.target.value).format('YYYY-MM-DD') }) }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('xCode').focus(); } }}
                                />
                            </FormControl>
                        </div>
                        <div>
                            <Grid container>
                                <Grid item xs={11}>
                                    <FormControl className={classes.formControlFullWidth}>
                                        <Autocomplete
                                            id="xCode"
                                            name="xCode"
                                            options={cashAccounts}
                                            getOptionLabel={option => option.title}
                                            selectOnFocus
                                            disableOpenOnFocus
                                            renderInput={params => <TextField {...params} variant="outlined" size="small" InputLabelProps={{ shrink: true }} label="Cash Account" />}
                                            value={model.xCode}
                                            title={model.xCode.groupName}
                                            onChange={(_, value) => {
                                                if(value === null) value = blankFormValue.xCode;
                                                setModel({ ...model, xCode: value });
                                            }}
                                            onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('yCode').focus(); } }}
                                        />
                                    </FormControl></Grid>
                                <Grid item xs={1}>
                                    <Button className={classes.makePrimaryAccount} title="Make Primary" onClick={makePrimaryAccount}>
                                        <StarIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <FormControl className={classes.formControlFullWidth}>
                                <Autocomplete
                                    id="yCode"
                                    name="yCode"
                                    options={accounts}
                                    getOptionLabel={option => option.title}
                                    selectOnFocus
                                    disableOpenOnFocus
                                    value={model.yCode}
                                    title={model.yCode.groupName}
                                    renderInput={params => <TextField {...params} variant="outlined" size="small" InputLabelProps={{ shrink: true }} label="Account" />}
                                    onChange={(_, value) => {
                                        if(value === null) value = blankFormValue.yCode;
                                        setModel({ ...model, yCode: value });
                                        setYCode(value.code);
                                    }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('amount').focus(); } }}
                                />
                            </FormControl>
                        </div>
                        <div>
                        <Grid container>
                                <Grid item xs={6}>
                            <FormControl className={classes.formControlFullWidth}>
                                <TextField
                                    id="amount"
                                    type="number"
                                    name="amount"
                                    label="Amount"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                    value={model.amount}
                                    onChange={(e) => { setModel({ ...model, amount: parseFloat(e.target.value) }) }}
                                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('narration').focus(); } }}
                                />
                            </FormControl>
                            </Grid>
                                <Grid item xs={6}>
                                    {yBalance && (
                                        <label className={classes.closingBalance}><b>Closing Balance: </b> {yBalance}</label>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            <FormControl className={classes.formControlFullWidth}>
                                <Autocomplete
                                    id="narration"
                                    name="narration"
                                    options={narrations.map(option => option.description)}
                                    freeSolo={true}
                                    disableOpenOnFocus
                                    renderInput={params => <TextField {...params} variant="outlined" size="small" InputLabelProps={{ shrink: true }} label="Narration" />}
                                    value={model.narration}
                                    onChange={(_, value) => { setModel({ ...model, narration: value }) }}
                                    onKeyUp={(e) => { setModel({ ...model, narration: e.target.value }); if (e.keyCode === 13) { document.getElementById('save').focus(); } }}
                                />
                            </FormControl>
                        </div>
                    </Grid>
                </Grid>
                <div>
                    {errors.map((error, index) => <div key={index}>{error}</div>)}
                </div>
                <div>
                    <Button id="save" variant="contained" size="small" color="primary" type="button" onClick={save}>
                        Save
                    </Button>
                </div>
            </form>

            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Vch No</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Accounts</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell>Narration</TableCell>
                            <TableCell className={classes.actionCol}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.map((row, index) => (
                            <TableRow hover key={index}>
                                <TableCell>{row.docNo}</TableCell>
                                <TableCell>{moment(row.date).format('DD/MM/YYYY')}</TableCell>
                                <TableCell><b>{row.yName}</b><br/>{row.xName}</TableCell>
                                <TableCell align="right">{formatMoney(row.amount)}</TableCell>
                                <TableCell>{row.narration}</TableCell>
                                <TableCell>
                                    <EditIcon className={classes.actionButton} onClick={() => edit(row)} />
                                    <DeleteIcon className={classes.actionButton} onClick={() => ddelete(row)} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button onClick={loadMore}>More...</Button>

            <ConfirmDialog model={deleteModel}
                show={showConfirmDelete}
                title="Confirm Delete"
                message="Are you sure you want to delete this transaction?"
                onClose={closeConfirmDeleteDialog}
                onYes={deleteTransaction} />
        </>
    );
}

export default connect(mapToProps, actions)(CashPayments);