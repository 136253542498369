import React, { useState, useEffect } from 'react';
import { Typography, Button, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, makeStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import AccountGroupEdit from './AccountGroupEdit';
import ConfirmDialog from '../ConfirmDialog';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginBottom: 10
    },
    actionButton: {
        cursor: 'pointer'
    }
}));

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function AccountGroups({ selectedSociety }) {
    const classes = useStyles();

    const [refreshData, setRefreshData] = React.useState(1);

    const [editMode, setEditMode] = React.useState(null);
    const [editModel, setEditModel] = React.useState();
    const [accountGroups, setAccountGroups] = useState([]);

    const [deleteModel, setDeleteModel] = React.useState();
    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

    useEffect(() => {
        axios.get('/accountgroup/list', { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setAccountGroups(response.data);
            });
    }, [selectedSociety, refreshData]);

    function create() {
        setEditMode('Create');
        setEditModel({ id: 0, code: '', name: '', isSystem: false, collapseInReports: false });
    }

    function edit(row) {
        setEditMode('Edit');
        setEditModel(row);
    }

    function closeEditModal() {
        setEditModel(null);
        setRefreshData(refreshData + 1);
    }

    function ddelete(row) {
        setDeleteModel(row);
        setShowConfirmDelete(true);
    }

    function closeConfirmDialog() {
        setShowConfirmDelete(false);
    }

    function deleteAccountGroup(row) {
        closeConfirmDialog();
        axios({
            method: 'DELETE',
            url: '/accountgroup/' + row.id,
            headers: { 'X-SocietyId': selectedSociety }
        })
            .then(response => {
                setRefreshData(refreshData + 1);
            })
            .catch(error => {
                alert(error.response.data.message);
            });
    }

    return (
        <>
            <Typography variant="h5">Account Groups</Typography>
            <div className={classes.topBar}>
                {window.decodedJwt.role === 'Administrator' &&
                    <Button variant="contained" size="small" color="primary" onClick={create}>Create</Button>
                }
            </div>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Report Appearance</TableCell>
                            <TableCell>System</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accountGroups.map((row, index) => (
                            <TableRow hover key={index}>
                                <TableCell>
                                    {row.isSystem ?
                                        (row.code) : (row.code)
                                    }
                                    <ErrorIcon style={{ visibility: 'hidden', color: 'red' }} />
                                </TableCell>
                                <TableCell>
                                    {row.isSystem ?
                                        (row.name) : (row.name)
                                    }
                                    <ErrorIcon style={{ visibility: 'hidden', color: 'red' }} />
                                </TableCell>
                                <TableCell>{row.collapseInReports ? 'Total only' : 'Detailed'}</TableCell>
                                <TableCell>{row.isSystem ? 'Yes' : 'No'}</TableCell>
                                <TableCell>
                                    {window.decodedJwt.role === 'Administrator' && <>
                                        <EditIcon className={classes.actionButton} onClick={() => edit(row)} />
                                        {!row.isSystem && (
                                            <>
                                                <DeleteIcon className={classes.actionButton} onClick={() => ddelete(row)} />
                                            </>)
                                        }
                                    </>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <AccountGroupEdit mode={editMode} model={editModel} onClose={closeEditModal} />
            <ConfirmDialog model={deleteModel}
                show={showConfirmDelete}
                title="Confirm Delete"
                message="Are you sure you want to delete this account group?"
                onClose={closeConfirmDialog}
                onYes={deleteAccountGroup} />
        </>
    );
}

export default connect(mapToProps, actions)(AccountGroups);