import React, { useState, useEffect } from 'react';
import { Typography, Paper, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TextField, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import { formatMoney } from '../../utils';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginBottom: 10
    },
    topBarControl: {
        marginLeft: 10
    }
}));

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function Bills({ selectedSociety }) {
    const classes = useStyles();

    const history = useHistory();
    const [refreshData, setRefreshData] = React.useState(1);
    const [billDates, setBillDates] = useState([]);
    const [nextBillDate, setNextBillDate] = useState('');
    const [nextDueDate, setNextDueDate] = useState('');

    useEffect(() => {
        axios.get('/bill/list', { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setBillDates(response.data);
            });
        axios.get('/bill/nextbilldate', { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setNextBillDate(response.data.billDate.substring(0, 10));
                setNextDueDate(response.data.dueDate.substring(0, 10));
            });
    }, [selectedSociety, refreshData]);

    function generateBills() {
        let confirmed = window.confirm('Are you sure you want to generate bills for ' + moment(nextBillDate).format('DD/MM/YYYY') + '?');

        if (confirmed) {
            let value = {
                billDate: nextBillDate,
                dueDate: nextDueDate,
                billMasterName: 'Default'
            };

            axios({
                method: 'POST',
                url: '/bill/generate',
                headers: { 'X-SocietyId': selectedSociety },
                data: JSON.stringify(value)
            })
                .then(response => {
                    setRefreshData(refreshData + 1);
                })
                .catch(error => {
                    alert(error.response.data.message);
                });
        }
    }

    function recalculate(row) {
        let confirmed = window.confirm('This will recalculate interest in all bills since this date. Continue?');

        if (confirmed) {
            axios({
                method: 'POST',
                url: '/bill/' + moment(row.date).format('YYYY-MM-DD') + '/recalculate',
                headers: { 'X-SocietyId': selectedSociety }
            })
                .then(response => {
                })
                .catch(error => {
                    alert(error.response.data.message);
                });
        }
    }

    function ddelete(row) {
        let confirmed = window.confirm('Are you sure you want to delete these bills?');

        if (confirmed) {
            axios({
                method: 'DELETE',
                url: '/bill/' + moment(row.date).format('YYYY-MM-DD'),
                headers: { 'X-SocietyId': selectedSociety }
            })
                .then(response => {
                    setRefreshData(refreshData + 1);
                })
                .catch(error => {
                    alert(error.response.data.message);
                });
        }
    }

    return (
        <>
            <Typography variant="h5">Bills</Typography>
            <div className={classes.topBar}>
                {window.decodedJwt.role === 'Administrator' && <>
                    <TextField
                        className={classes.topBarControl}
                        type="date"
                        label="Next Bill Date"
                        value={nextBillDate}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => { setNextBillDate(e.target.value) }} />
                    <TextField
                        className={classes.topBarControl}
                        type="date"
                        label="Due Date"
                        value={nextDueDate}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => { setNextDueDate(e.target.value) }} />
                    <Button className={classes.topBarControl} variant="contained" size="small" color="primary" onClick={generateBills}>Generate Bills</Button>
                </>}
            </div>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Interest Rate</TableCell>
                            <TableCell>Last Calculated</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {billDates.map((row, index) => (
                            <TableRow hover key={row.date} onClick={() => {
                                history.push('/bills/' + moment(row.date).format('YYYY-MM-DD'));
                            }}>
                                <TableCell>{moment(row.date).format('DD/MM/YYYY')}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>{formatMoney(row.latePaymentInterestRate)}%</TableCell>
                                <TableCell>{moment(row.lastCalculated).format('DD/MM/YYYY')}</TableCell>
                                <TableCell>
                                    {window.decodedJwt.role === 'Administrator' && <>
                                        <Button className={classes.topBarControl}
                                            variant="contained"
                                            size="small"
                                            color="default"
                                            onClick={(e) => { recalculate(row); e.stopPropagation() }}>Recalculate</Button>
                                        {index === 0 && (
                                            <Button variant="contained"
                                                size="small"
                                                color="secondary"
                                                onClick={(e) => { ddelete(row); e.stopPropagation() }}>Delete</Button>
                                        )}
                                    </>}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default connect(mapToProps, actions)(Bills);