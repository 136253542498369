import React, { useState } from 'react';
import { Button, CssBaseline, FormControl, Link, Box, Typography, Container, makeStyles } from '@material-ui/core';
import { connect } from 'redux-zero/react';
import actions from '../actions';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import SharpTextField from './SharpTextField';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sharpsociety.in">
        Sharp Accounting Services
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  formControlFullWidth: {
    margin: theme.spacing(1, 0),
    width: '100%',
  },
  error: {
    color: 'red'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginBottom: 10
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cityscape: {
    zIndex: -1,
    opacity: 0.4,
    position: 'fixed',
    left: 0,
    bottom: 0,
    height: 250,
    width: '1000%',
    backgroundImage: 'url(/images/cityscape.png)',
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'bottom left',
    animation: 'slide 90s linear infinite'
  }
}));

const mapToProps = ({ isAuthenticated }) => ({ isAuthenticated });

function SignIn({ setIsAuthenticated }) {
  const classes = useStyles();

  const [model] = useState({ contactName: '', email: '', mobile: '', name: '', address: '', maxSocieties: 1, maxBusinessUsers: 1 });
  const [error, setError] = useState('');
  const history = useHistory();

  function onSubmit(value) {
    setError('');

    axios({
      method: 'POST',
      url: '/business/register',
      data: JSON.stringify(value)
    })
      .then(response => {
        history.push('/registered');
      })
      .catch(error => {
        setError(error.response.message);
      });
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.logo} src="images/sharp_logo3.svg" alt="Sharp Logo" />
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <Formik initialValues={model}
          enableReinitialize={true}
          validationSchema={Yup.object({
            contactName: Yup.string().required('Required'),
            email: Yup.string().required('Required'),
            mobile: Yup.string().required('Required'),
            name: Yup.string().required('Required'),
            address: Yup.string().required('Required')
          })}
          validateOnMount={true}
          onSubmit={onSubmit}
        >
          {({ isValid }) => (
            <Form className={classes.form} autoComplete="off" onKeyDown={(e) => { if (e.keyCode === 13 && e.target.id !== 'save') e.preventDefault(); }}>
              <div>
                <FormControl className={classes.formControlFullWidth}>
                  <SharpTextField
                    id="contactName"
                    name="contactName"
                    label="Your Name"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    autofocus
                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('email').focus(); } }}
                  />
                </FormControl>
                <FormControl className={classes.formControlFullWidth}>
                  <SharpTextField
                    id="email"
                    name="email"
                    label="Email"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('mobile').focus(); } }}
                  />
                </FormControl>
                <FormControl className={classes.formControlFullWidth}>
                  <SharpTextField
                    id="mobile"
                    name="mobile"
                    label="Mobile"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('name').focus(); } }}
                  />
                </FormControl>
                <FormControl className={classes.formControlFullWidth}>
                  <SharpTextField
                    id="name"
                    name="name"
                    label="Society or Business Name"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('address').focus(); } }}
                  />
                </FormControl>
                <FormControl className={classes.formControlFullWidth}>
                  <SharpTextField
                    id="address"
                    name="address"
                    label="Address"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('register').focus(); } }}
                  />
                </FormControl>
              </div>
              <div>
                {error}
              </div>
              <div>
                <Button id="register" className={classes.formControlFullWidth} variant="contained" color="primary" type="submit" disabled={!isValid}>
                  Register
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <div className={classes.cityscape}>
      </div>
    </Container>
  );
}

export default connect(mapToProps, actions)(SignIn);