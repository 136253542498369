import React, { useState, useEffect } from 'react';
import { Typography, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button, TextField, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import moment from 'moment';
import { formatMoney } from '../../utils';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginBottom: 10
    },
    topBarLeftControl: {
        marginLeft: 10
    },
    topBarControl: {
        float: 'right',
        marginLeft: 10
    }
}));

const mapToProps = ({ selectedSociety, currentPeriod }) => ({ selectedSociety, currentPeriod });

function BalanceSheetReport({ selectedSociety, currentPeriod }) {
    const classes = useStyles();

    const [report, setReport] = useState({ items: [] });
    const [fromDate, setFromDate] = useState(currentPeriod.startDate);
    const [toDate, setToDate] = useState(currentPeriod.endDate);

    useEffect(() => {
        if (moment(fromDate).year() < 1000 || moment(toDate).year() < 1000) return;
        
        axios.get('/report/balancesheet/' + moment(fromDate).format('YYYY-MM-DD') + '/' + moment(toDate).format('YYYY-MM-DD'),
            { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setReport(response.data);
            });
    }, [selectedSociety, fromDate, toDate]);

    function downloadPdf() {
        axios.get('/report/pdf/balancesheet/' + moment(fromDate).format('YYYY-MM-DD') + '/' + moment(toDate).format('YYYY-MM-DD'),
            { headers: { 'X-SocietyId': selectedSociety }, responseType: 'arraybuffer' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                window.open(window.URL.createObjectURL(blob));
            });
    }

    return (
        <>
            <Typography variant="h5">Balance Sheet</Typography>
            <div className={classes.topBar}>
                <TextField className={classes.topBarLeftControl}
                    type="date"
                    value={fromDate}
                    label="From"
                    onChange={(e) => { setFromDate(e.target.value) }} />
                <TextField className={classes.topBarLeftControl}
                    type="date"
                    value={toDate}
                    label="To"
                    onChange={(e) => { setToDate(e.target.value) }} />
                <Button className={classes.topBarControl} onClick={downloadPdf} variant="contained" size="small" color="primary">PDF</Button>
            </div>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell><h2>Liabilities</h2></TableCell>
                            <TableCell></TableCell>
                            <TableCell><h2>Assets</h2></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right">Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {report.items.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell align={item.isTotal1 && !item.isGroup1 ? 'right' : 'left'}>{item.isGroup1 || item.isTotal1 ? (<b>{item.name1}</b>) : item.name1}&nbsp;</TableCell>
                                <TableCell align="right">{item.isTotal1 ? (<b>{formatMoney(item.closingBalance1)}</b>) : formatMoney(item.closingBalance1)}</TableCell>
                                <TableCell align={item.isTotal2 && !item.isGroup2 ? 'right' : 'left'}>{item.isGroup2 || item.isTotal2 ? (<b>{item.name2}</b>) : item.name2}&nbsp;</TableCell>
                                <TableCell align="right">{item.isTotal2 ? (<b>{formatMoney(item.closingBalance2)}</b>) : formatMoney(item.closingBalance2)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default connect(mapToProps, actions)(BalanceSheetReport);