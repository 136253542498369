import React, { useState, useEffect } from 'react';
import { Typography, Paper, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../actions';
import ConfirmDialog from './ConfirmDialog';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginBottom: 10
    },
    actionButton: {
        cursor: 'pointer'
    },
    actionCol: {
        width: 20
    }
}));

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function SwitchSociety({ selectedSociety, setSelectedSociety, setSelectedSocietyName, setCurrentPeriod }) {
    const classes = useStyles();
    const history = useHistory();

    const [refreshData, setRefreshData] = React.useState(1);
    const [societyHeaders, setSocietyHeaders] = useState([]);
    const [deleteModel, setDeleteModel] = React.useState();
    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

    useEffect(() => {
        setSelectedSociety(0);
        setSelectedSocietyName('');
        axios.get('/society/list')
            .then(response => {
                setSocietyHeaders(response.data);
            });
    }, [refreshData, setSelectedSociety, setSelectedSocietyName]);

    function ddelete(row) {
        setDeleteModel(row);
        setShowConfirmDelete(true);
    }

    function closeConfirmDialog() {
        setShowConfirmDelete(false);
    }

    function deleteSociety(row) {
        closeConfirmDialog();
        axios({
            method: 'DELETE',
            url: '/society/' + row.id
        })
            .then(response => {
                setRefreshData(refreshData + 1);
            });
    }

    return (
        <>
            <Typography variant="h5">Select Society</Typography>
            <div>
                {window.decodedJwt.role === 'Administrator' && <Button className={classes.topBar} variant="contained" color="primary" size="small" onClick={() => {
                    setSelectedSociety(0);
                    setSelectedSocietyName('');
                    history.push('/societydetails');
                }}>Create Society</Button>
                }
            </div>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell className={classes.actionCol}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {societyHeaders.map(row => (
                            <TableRow hover key={row.id} onClick={() => {
                                setSelectedSociety(row.id);
                                setSelectedSocietyName(row.name);
                                setCurrentPeriod({ startDate: row.currentPeriodFrom, endDate: row.currentPeriodTo });
                                history.push('/transactions');
                            }}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                    <DeleteIcon className={classes.actionButton} onClick={(e) => { e.stopPropagation(); ddelete(row); }} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <ConfirmDialog model={deleteModel}
                show={showConfirmDelete}
                title="Confirm Delete"
                message="Are you sure you want to delete this society? This operation CANNOT BE UNDONE!"
                onClose={closeConfirmDialog}
                onYes={deleteSociety} />
        </>
    );
}

export default connect(mapToProps, actions)(SwitchSociety);