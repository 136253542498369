import React, { useState, useEffect } from 'react';
import { Typography, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Button, TextField, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import moment from 'moment';
import { formatMoney } from '../../utils';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginBottom: 10
    },
    topBarLeftControl: {
        marginLeft: 10
    },
    topBarControl: {
        float: 'right',
        marginLeft: 10
    }
}));

const mapToProps = ({ selectedSociety, currentPeriod }) => ({ selectedSociety, currentPeriod });

function JournalVouchersReport({ selectedSociety, currentPeriod }) {
    const classes = useStyles();

    const [items, setItems] = useState([]);
    const [fromDate, setFromDate] = useState(currentPeriod.startDate);
    const [toDate, setToDate] = useState(currentPeriod.endDate);

    useEffect(() => {
        if (moment(fromDate).year() < 1000 || moment(toDate).year() < 1000) return;
        
        axios.get('/journalvoucher/list/' + moment(fromDate).format('YYYY-MM-DD') + '/' + moment(toDate).format('YYYY-MM-DD'),
            { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setItems(response.data);
            });
    }, [selectedSociety, fromDate, toDate]);

    function downloadPdf() {
        axios.get('/report/pdf/journalvouchers/' + moment(fromDate).format('YYYY-MM-DD') + '/' + moment(toDate).format('YYYY-MM-DD'),
            { headers: { 'X-SocietyId': selectedSociety }, responseType: 'arraybuffer' })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                window.open(window.URL.createObjectURL(blob));
            });
    }

    return (
        <>
            <Typography variant="h5">Journal Vouchers</Typography>
            <div className={classes.topBar}>
                <TextField className={classes.topBarLeftControl}
                    type="date"
                    value={fromDate}
                    label="From"
                    onChange={(e) => { setFromDate(e.target.value) }} />
                <TextField className={classes.topBarLeftControl}
                    type="date"
                    value={toDate}
                    label="To"
                    onChange={(e) => { setToDate(e.target.value) }} />
                <Button className={classes.topBarControl} onClick={downloadPdf} variant="contained" size="small" color="primary">PDF</Button>
            </div>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Voucher No</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Entries</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((row, index) => (
                            <TableRow hover key={index}>
                                <TableCell>{row.docNo}</TableCell>
                                <TableCell>{moment(row.date).format('DD/MM/YYYY')}</TableCell>
                                <TableCell>
                                    <Table aria-label="simple table" size="small">
                                        <TableBody>
                                            {row.items.map((entry, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{entry.name}</TableCell>
                                                    <TableCell align="right">{formatMoney(entry.amount)} {entry.dc}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default connect(mapToProps, actions)(JournalVouchersReport);