import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import jwtdecode from 'jwt-decode';

axios.defaults.baseURL = window.config.apiUrl;
// This does not work for Content-Type --> axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.delete['Content-Type'] = 'application/json';

const accessTokenKey = 'accessToken';
let accessToken = localStorage.getItem(accessTokenKey);
if (accessToken) {
    window.decodedJwt = jwtdecode(accessToken);
}

axios.interceptors.request.use(config => {
    let accessToken = localStorage.getItem(accessTokenKey);
    if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    let accessToken = response.headers['x-token'];
    if (accessToken) {
        localStorage.setItem(accessTokenKey, accessToken);
        window.decodedJwt = jwtdecode(accessToken);
    }
    return response;
}, error => {
    if (!error || !error.response || (error.response.status === 401 && error.response.config.url !== '/user/signin')) {
        localStorage.removeItem(accessTokenKey);
        window.location = '/signin';
    }
    return Promise.reject(error);
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
