import React, { useState, useEffect } from 'react';
import { Typography, Paper, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PeopleIcon from '@material-ui/icons/People';
import ApartmentIcon from '@material-ui/icons/Apartment';
import axios from 'axios';
import moment from 'moment';
import BusinessEdit from './BusinessEdit';
import ConfirmDialog from '../ConfirmDialog';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginBottom: 10
    },
    actionButton: {
        cursor: 'pointer',
        marginRight: 3
    },
    registered: {
        color: 'red'
    }
}));

function Businesses() {
    const classes = useStyles();
    const history = useHistory();

    const [refreshData, setRefreshData] = React.useState(1);

    const [businesses, setBusinesses] = useState([]);
    const [editMode, setEditMode] = React.useState(null);
    const [editModel, setEditModel] = React.useState();

    const [deleteModel, setDeleteModel] = React.useState();
    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

    useEffect(() => {
        axios.get('/business/list')
            .then(response => {
                setBusinesses(response.data);
            });
    }, [refreshData]);

    function create() {
        setEditMode('Create');
        setEditModel({
            id: 0, name: '', address: '', telephone: '', mobile: '',
            packageName: 'Free', packageExpiry: moment().format('YYYY-MM-DD'), maxSocieties: 1, maxBusinessUsers: 1
        });
    }

    function closeEditModal() {
        setEditModel(null);
        setRefreshData(refreshData + 1);
    }

    function edit(row) {
        setEditMode('Edit');
        setEditModel(row);
    }

    function ddelete(row) {
        setDeleteModel(row);
        setShowConfirmDelete(true);
    }

    function closeConfirmDialog() {
        setShowConfirmDelete(false);
    }

    function deleteBusiness(row) {
        closeConfirmDialog();
        axios({
            method: 'DELETE',
            url: '/business/' + row.id
        })
            .then(response => {
                setRefreshData(refreshData + 1);
            });
    }

    function navigateToUsers(businessId) {
        history.push(`/businesses/${businessId}/users`);
    }

    function navigateToSocieties(businessId) {
        history.push(`/businesses/${businessId}/societies`);
    }

    return (
        <>
            <Typography variant="h5">Businesses</Typography>
            <div>
                <Button className={classes.topBar} variant="contained" color="primary" size="small" onClick={create}>Create Business</Button>
            </div>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Contact Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>Package</TableCell>
                            <TableCell>Package Expiry</TableCell>
                            <TableCell>Max Societies</TableCell>
                            <TableCell>Max Business Users</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {businesses.map(row => (
                            <TableRow hover key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.contactName}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.mobile}</TableCell>
                                <TableCell>{row.packageName === 'Registered' ? <b className={classes.registered}>{row.packageName}</b> : row.packageName}</TableCell>
                                <TableCell>{moment(row.packageExpiry).format('DD/MM/YYYY')}</TableCell>
                                <TableCell>{row.maxSocieties === 0 ? 'Unlimited' : row.maxSocieties}</TableCell>
                                <TableCell>{row.maxBusinessUsers === 0 ? 'Unlimited' : row.maxBusinessUsers}</TableCell>
                                <TableCell>
                                    <PeopleIcon className={classes.actionButton} title="Users" onClick={() => navigateToUsers(row.id)} />
                                    <ApartmentIcon className={classes.actionButton} title="Societies" onClick={() => navigateToSocieties(row.id)} />
                                </TableCell>
                                <TableCell>
                                    <EditIcon className={classes.actionButton} onClick={() => edit(row)} />
                                    <DeleteIcon className={classes.actionButton} onClick={() => ddelete(row)} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <BusinessEdit mode={editMode} model={editModel} onClose={closeEditModal} />
            <ConfirmDialog model={deleteModel}
                show={showConfirmDelete}
                title="Confirm Delete"
                message="Are you sure you want to delete this business?"
                onClose={closeConfirmDialog}
                onYes={deleteBusiness} />
        </>
    );
}

export default Businesses;