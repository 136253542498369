import React, { useState } from 'react';
import { Button, FormControl, Modal, makeStyles, MenuItem, Grid } from '@material-ui/core';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import SharpTextField from '../SharpTextField';
import SharpSelectField from '../SharpSelectField';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 500
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 800
    },
    formControlFullWidth: {
        margin: theme.spacing(1, 0),
        width: '100%',
    },
}));

function BusinessUserEdit({ mode, model, onClose }) {
    const classes = useStyles();
    const [error, setError] = useState('');

    function onSubmit(value) {
        setError('');

        if (mode === 'Create') {
            axios({
                method: 'POST',
                url: '/user',
                data: JSON.stringify(value)
            })
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    setError(error.response.data.message);
                });
        } else if (mode === 'Edit') {
            axios({
                method: 'PUT',
                url: '/user/' + model.id,
                data: JSON.stringify(value)
            })
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    setError(error.response.data.message);
                });
        }
    }

    return (
        <Modal className={classes.modal} open={(model != null)} onClose={onClose}>
            <div className={classes.paper}>
                <h2 id="transition-modal-title">{mode} Business User</h2>
                <Formik initialValues={model}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        email: Yup.string().required('Required'),
                        mobile: Yup.string().required('Required'),
                        firstName: Yup.string().required('Required'),
                        middleName: Yup.string(),
                        lastName: Yup.string().required('Required'),
                        role: Yup.string().required('Required')
                    })}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {({ isValid }) => (
                        <Form autoComplete="off" onKeyDown={(e) => { if (e.keyCode === 13 && e.target.id !== 'save') e.preventDefault(); }}>
                            <div>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="email"
                                                name="email"
                                                label="Email"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('mobile').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="mobile"
                                                name="mobile"
                                                label="Mobile"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('firstName').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="firstName"
                                                name="firstName"
                                                label="First Name"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('middleName').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="middleName"
                                                name="middleName"
                                                label="Middle Name"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('lastName').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="lastName"
                                                name="lastName"
                                                label="Last Name"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('role').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpSelectField
                                                id="role"
                                                name="role"
                                                label="Role"
                                                fullWidth
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('status').focus(); } }}
                                            >
                                                <MenuItem value={'Administrator'}>Administrator</MenuItem>
                                                <MenuItem value={'Operator'}>Operator</MenuItem>
                                            </SharpSelectField>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                {error}
                            </div>
                            <div>
                                <Button id="save" variant="contained" color="primary" type="submit" disabled={!isValid}>
                                    Save
                                </Button>
                                <Button variant="contained" color="secondary" type="submit" onClick={() => { setError(''); onClose(); }}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    );
}

export default BusinessUserEdit;