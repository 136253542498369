import React, { useState } from 'react';
import { Button, CssBaseline, TextField, Typography, Container, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    error: {
        color: 'red'
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        marginBottom: 10
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    cityscape: {
        zIndex: -1,
        opacity: 0.4,
        position: 'fixed',
        left: 0,
        bottom: 0,
        height: 250,
        width: '1000%',
        backgroundImage: 'url(/images/cityscape.png)',
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'bottom left',
        animation: 'slide 90s linear infinite'
    }
}));

function ChangePassword() {
    const history = useHistory();
    const classes = useStyles();

    const [model, setModel] = useState({ currentPassword: '', newPassword: '' });
    const [error, setError] = useState('');

    function setPassword() {
        axios({
            method: 'POST',
            url: '/user/changepassword',
            data: JSON.stringify(model)
        })
            .then(response => {
                setError('');
                history.push('/');
            })
            .catch(error => {
                setError('Change password failed');
            });
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">Change Password</Typography>
                <div className={classes.form}>
                    <TextField variant="outlined" margin="normal" required fullWidth id="currentPassword" type="password"
                        label="Current Password" name="currentPassword" autoFocus
                        onChange={(e) => setModel({ ...model, currentPassword: e.target.value })} />
                    <TextField variant="outlined" margin="normal" required fullWidth id="newPassword" type="password"
                        label="New Password" name="newPassword"
                        onChange={(e) => setModel({ ...model, newPassword: e.target.value })} />
                    <div className={classes.error}>
                        {error}
                    </div>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={setPassword}
                    >
                        Change Password
                    </Button>
                </div>
            </div>
        </Container>
    );
}

export default ChangePassword;