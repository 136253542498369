import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Checkbox,
  TextField,
  makeStyles,
} from "@material-ui/core";
import axios from "axios";
import { connect } from "redux-zero/react";
import actions from "../../actions";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  topBar: {
    marginBottom: 10,
  },
  topBarCheckBox: {
    marginLeft: 10,
    marginRight: 30,
    marginTop: 10,
  },
  topBarLeftControl: {
    marginLeft: 10,
  },
  topBarControl: {
    marginLeft: 10,
  },
  firstPageNo: {
    width: 100,
  },
}));

const mapToProps = ({ selectedSociety, currentPeriod }) => ({
  selectedSociety,
  currentPeriod,
});

function MemberBalanceReport({ selectedSociety, currentPeriod }) {
  const classes = useStyles();

  const [fromDate, setFromDate] = useState(currentPeriod.startDate);
  const [toDate, setToDate] = useState(currentPeriod.endDate);
  const [firstPageNo, setFirstPageNo] = useState(1);

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (moment(fromDate).year() < 1000 || moment(toDate).year() < 1000) return;

    axios
      .get(
        "/account/listwithtransactioncount/" +
          moment(fromDate).format("YYYY-MM-DD") +
          "/" +
          moment(toDate).format("YYYY-MM-DD"),
        { headers: { "X-SocietyId": selectedSociety } }
      )
      .then((response) => {
        var v = response.data;
        for (let item of v) {
          if (
            item.type !== "Cash" &&
            item.type !== "Bank" &&
            item.transactionCount > 0
          ) {
            item.selected = true;
          } else {
            item.selected = false;
          }
        }
        setItems(v);
      });
  }, [selectedSociety, fromDate, toDate]);

  function selectAll() {
    for (let item of items) {
      item.selected = true;
    }
    setItems(items.slice());
  }

  function selectCashWithTransactions() {
    for (let item of items) {
      if (item.type === "Cash" && item.transactionCount > 0) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    }
    setItems(items.slice());
  }

  function selectBanksWithTransactions() {
    for (let item of items) {
      if (item.type === "Bank" && item.transactionCount > 0) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    }
    setItems(items.slice());
  }

  function selectWithTransactions() {
    for (let item of items) {
      if (
        item.type !== "Cash" &&
        item.type !== "Bank" &&
        item.transactionCount > 0
      ) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    }
    setItems(items.slice());
  }

  function selectMembers() {
    for (let item of items) {
      if (item.type === "Member" && item.transactionCount > 0) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    }
    setItems(items.slice());
  }

  function deselectAll() {
    for (let item of items) {
      item.selected = false;
    }
    setItems(items.slice());
  }

  function downloadPdf() {
    let selected = [];
    for (let item of items) {
      if (item.selected) selected.push(item.code);
    }

    if (selected.length === 0) {
      alert("No accounts selected");
      return;
    }

    axios({
      method: "POST",
      url: "/report/pdf/multipleledgers",
      headers: { "X-SocietyId": selectedSociety },
      data: JSON.stringify({
        codes: Array.from(selected),
        startDate: moment(fromDate).format("YYYY-MM-DD"),
        endDate: moment(toDate).format("YYYY-MM-DD"),
        firstPageNo: parseInt(firstPageNo),
      }),
      responseType: "arraybuffer",
    }).then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });
      let tab = window.open();
      tab.location = window.URL.createObjectURL(blob);
    });
  }

  return (
    <>
      <Typography variant="h5">Multiple Ledgers</Typography>
      <div className={classes.topBar}>
        <TextField
          className={classes.topBarLeftControl}
          type="date"
          value={fromDate}
          label="From"
          onChange={(e) => {
            setFromDate(e.target.value);
          }}
        />
        <TextField
          className={classes.topBarLeftControl}
          type="date"
          value={toDate}
          label="To"
          onChange={(e) => {
            setToDate(e.target.value);
          }}
        />
      </div>
      <div className={classes.topBar}>
        <TextField
          className={classes.firstPageNo}
          type="number"
          value={firstPageNo}
          label="First Page No"
          onChange={(e) => {
            setFirstPageNo(e.target.value);
          }}
        />
        <Button
          onClick={downloadPdf}
          variant="contained"
          size="small"
          color="primary"
          className={classes.topBarControl}
        >
          PDF
        </Button>
        <Button
          onClick={deselectAll}
          variant="contained"
          size="small"
          color="primary"
          className={classes.topBarControl}
        >
          Deselect All
        </Button>
        <Button
          onClick={selectAll}
          variant="contained"
          size="small"
          color="primary"
          className={classes.topBarControl}
        >
          Select All
        </Button>
        <Button
          onClick={selectCashWithTransactions}
          variant="contained"
          size="small"
          color="primary"
          className={classes.topBarControl}
        >
          Select Cash
        </Button>
        <Button
          onClick={selectBanksWithTransactions}
          variant="contained"
          size="small"
          color="primary"
          className={classes.topBarControl}
        >
          Select Banks
        </Button>
        <Button
          onClick={selectWithTransactions}
          variant="contained"
          size="small"
          color="primary"
          className={classes.topBarControl}
        >
          Select Ledgers
        </Button>
        <Button
          onClick={selectMembers}
          variant="contained"
          size="small"
          color="primary"
          className={classes.topBarControl}
        >
          Select Members
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Group</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>
                Transactions (Includes non-zero opening balance)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) => (
              <TableRow hover key={index}>
                <TableCell>
                  <Checkbox
                    checked={row.selected}
                    onChange={(e) => {
                      row.selected = e.target.checked;
                      setItems(items.slice());
                    }}
                  />
                </TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.accountName}</TableCell>
                <TableCell>{row.groupName}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.transactionCount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default connect(mapToProps, actions)(MemberBalanceReport);
