import React from "react";
import Layout from "./components/Layout";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "redux-zero/react";
import { SnackbarProvider } from "notistack";
import store from "./store";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { blue, orange } from "@material-ui/core/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: blue[800],
    },
    secondary: orange,
  },
  shape: {
    borderRadius: 1,
  },
  overrides: {
    MuiInputBase: {
      input: {
        background: "#fefefe",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "10px 14px",
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(14px, -6px) scale(0.75)",
        zIndex: 1,
      },
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
    },
    MuiSelect: {
      variant: "outlined",
    },
    MuiAutocomplete: {
      variant: "outlined",
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider autoHideDuration={1200}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Layout />
          </ThemeProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
