import React, { useState, useEffect } from 'react';
import {
    Typography, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    Select, Button, Checkbox, MenuItem, TextField, FormControl, FormControlLabel, InputLabel, makeStyles
} from '@material-ui/core';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import moment from 'moment';
import { formatMoney } from '../../utils';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginBottom: 10
    },
    topBarCheckBox: {
        marginLeft: 10,
        marginRight: 30
    },
    topBarLeftControl: {
        marginLeft: 10
    },
    topBarControl: {
        marginLeft: 10
    }
}));

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function MemberBalanceReport({ selectedSociety }) {
    const classes = useStyles();

    const [onlyBilling, setonlyBilling] = useState(true);
    const [transactionType, setTransactionType] = useState('BR');
    const [fromDate, setFromDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

    const [items, setItems] = useState([]);

    useEffect(() => {
        if (moment(fromDate).year() < 1000 || moment(toDate).year() < 1000) return;

        axios.get('/transaction/listbydate/' + transactionType + '/' + (onlyBilling ? 'billing' : 'any') + '/' +
                    moment(fromDate).format('YYYY-MM-DD') + '/' + moment(toDate).format('YYYY-MM-DD') + '/asc/0',
            { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                var v = response.data;
                for (let item of v) {
                    item.selected = true;
                }
                setItems(v);
            });
    }, [selectedSociety, onlyBilling, transactionType, fromDate, toDate]);

    function selectAll() {
        for (let item of items) {
            item.selected = true;
        }
        setItems(items.slice());
    }

    function deselectAll() {
        for (let item of items) {
            item.selected = false;
        }
        setItems(items.slice());
    }

    function downloadPdf() {
        let selected = [];
        for (let item of items) {
            if (item.selected) selected.push(item.docNo);
        }

        if (selected.length === 0) {
            alert("No receipts/vouchers selected");
            return;
        }

        axios({
            method: 'POST',
            url: '/report/pdf/receiptsvouchers',
            headers: { 'X-SocietyId': selectedSociety },
            data: JSON.stringify({ type: transactionType, docNos: Array.from(selected) }),
            responseType: 'arraybuffer'
        })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                window.open(window.URL.createObjectURL(blob));
            });
    }

    function downloadExcel() {
        let selected = [];
        for (let item of items) {
            if (item.selected) selected.push(item.docNo);
        }

        if (selected.length === 0) {
            alert("No receipts/vouchers selected");
            return;
        }

        axios({
            method: 'POST',
            url: '/report/excel/receiptsvoucherslist',
            headers: { 'X-SocietyId': selectedSociety },
            data: JSON.stringify({ type: transactionType, docNos: Array.from(selected) }),
            responseType: 'arraybuffer'
        })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'List.xlsx';
                link.click();
            });
    }

    function downloadListPdf() {
        let selected = [];
        for (let item of items) {
            if (item.selected) selected.push(item.docNo);
        }

        if (selected.length === 0) {
            alert("No receipts/vouchers selected");
            return;
        }

        axios({
            method: 'POST',
            url: '/report/pdf/receiptsvoucherslist',
            headers: { 'X-SocietyId': selectedSociety },
            data: JSON.stringify({ type: transactionType, docNos: Array.from(selected) }),
            responseType: 'arraybuffer'
        })
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                window.open(window.URL.createObjectURL(blob));
            });
    }

    return (
        <>
            <Typography variant="h5">Receipts/Vouchers</Typography>
            <div className={classes.topBar}>
                <FormControl className={classes.topBarLeftControl}>
                    <InputLabel id="type-label">Type</InputLabel>
                    <Select
                        labelId="type-label"
                        value={transactionType}
                        onChange={(e) => { setTransactionType(e.target.value) }}>
                        <MenuItem value={'BR'}>Bank Receipts</MenuItem>
                        <MenuItem value={'BP'}>Bank Payments</MenuItem>
                        <MenuItem value={'CR'}>Cash Receipts</MenuItem>
                        <MenuItem value={'CP'}>Cash Payments</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel label="Billing only" className={classes.topBarCheckBox}
                    control={<Checkbox checked={onlyBilling}
                        onChange={() => { setonlyBilling(!onlyBilling) }} />} />
                <TextField className={classes.topBarLeftControl}
                    type="date"
                    value={fromDate}
                    label="From"
                    onChange={(e) => { setFromDate(e.target.value) }} />
                <TextField className={classes.topBarLeftControl}
                    type="date"
                    value={toDate}
                    label="To"
                    onChange={(e) => { setToDate(e.target.value) }} />
            </div>
            <div className={classes.topBar}>
                <Button onClick={downloadListPdf} variant="contained" size="small" color="primary" className={classes.topBarControl}>List PDF</Button>
                <Button onClick={downloadExcel} variant="contained" size="small" color="primary" className={classes.topBarControl}>List Excel</Button>
                <Button onClick={downloadPdf} variant="contained" size="small" color="primary" className={classes.topBarControl}>PDF</Button>
                <Button onClick={deselectAll} variant="contained" size="small" color="primary" className={classes.topBarControl}>Deselect All</Button>
                <Button onClick={selectAll} variant="contained" size="small" color="primary" className={classes.topBarControl}>Select All</Button>
            </div>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{transactionType.substr(1, 1) === 'R' ? 'Receipt No' : 'Voucher No'}</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>{transactionType.substr(0, 1) === 'B' ? 'Bank Account' : 'Cash Account'}</TableCell>
                            <TableCell>Account</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell>Cheque No</TableCell>
                            <TableCell>Cheque Bank</TableCell>
                            <TableCell>Narration</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((row, index) => (
                            <TableRow hover key={index}>
                                <TableCell><Checkbox checked={row.selected} onChange={(e) => { row.selected = e.target.checked; setItems(items.slice()) }} /></TableCell>
                                <TableCell>{row.docNo}</TableCell>
                                <TableCell>{moment(row.date).format('DD/MM/YYYY')}</TableCell>
                                <TableCell>{row.xName}</TableCell>
                                <TableCell>{row.yName} {row.yUnit ? '(Flat No. ' + row.yUnit + ')' : ''}</TableCell>
                                <TableCell align="right">{formatMoney(row.amount)}</TableCell>
                                <TableCell>{row.chequeNo}</TableCell>
                                <TableCell>{row.chequeBank}</TableCell>
                                <TableCell>{row.narration}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default connect(mapToProps, actions)(MemberBalanceReport);