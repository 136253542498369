import React, { useState, useEffect } from 'react';
import { Typography, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function BusinessSocieties({ selectedSociety, setSelectedSociety, setSelectedSocietyName, setCurrentPeriod }) {
    const history = useHistory();
    const [societyHeaders, setSocietyHeaders] = useState([]);

    let { id } = useParams();
    let businessId = parseInt(id);

    useEffect(() => {
        setSelectedSociety(0);
        setSelectedSocietyName('');
        axios.get(`/society/listbybusiness/${businessId}`)
            .then(response => {
                setSocietyHeaders(response.data);
            });
    }, [setSelectedSociety, setSelectedSocietyName, businessId]);

    return (
        <>
            <Typography variant="h5">Business Societies</Typography>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {societyHeaders.map(row => (
                            <TableRow hover key={row.id} onClick={() => {
                                setSelectedSociety(row.id);
                                setSelectedSocietyName(row.name);
                                setCurrentPeriod({ startDate: row.currentPeriodFrom, endDate: row.currentPeriodTo });
                                history.push('/transactions');
                            }}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default connect(mapToProps, actions)(BusinessSocieties);