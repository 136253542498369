import React, { useState } from 'react';
import { Button, FormControl, Modal, MenuItem, Grid, makeStyles } from '@material-ui/core';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import SharpTextField from '../SharpTextField';
import SharpSelectField from '../SharpSelectField';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 800,
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: 800
    },
    formControlFullWidth: {
        margin: theme.spacing(1, 0),
        width: '100%',
    },
}));

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function MemberEdit({ selectedSociety, mode, model, onClose }) {
    const classes = useStyles();
    const [error, setError] = useState('');

    function onSubmit(formvalue) {
        setError('');

        let value = Object.assign({}, formvalue);
        if (value.buildingNo === '') {
            value.buildingNo = null;
        }

        if (value.wing === '') {
            value.wing = null;
        }

        if (value.unit === '') {
            value.unit = null;
        }

        if (value.area === '') {
            value.area = null;
        }

        if (value.areaSqmt === '') {
            value.areaSqmt = null;
        }

        if (value.title === '') {
            value.title = null;
        }

        if (value.middleName === '') {
            value.middleName = null;
        }

        if (value.email === '') {
            value.email = null;
        }

        if (value.phone === '') {
            value.phone = null;
        }

        if (value.mobile === '') {
            value.mobile = null;
        }

        if (mode === 'Create') {
            axios({
                method: 'POST',
                url: '/member',
                headers: { 'X-SocietyId': selectedSociety },
                data: JSON.stringify(value)
            })
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    setError('Error: ' + error.response.data.message);
                });
        } else if (mode === 'Edit') {
            axios({
                method: 'PUT',
                url: '/member/' + model.id,
                headers: { 'X-SocietyId': selectedSociety },
                data: JSON.stringify(value)
            })
                .then(response => {
                    onClose();
                })
                .catch(error => {
                    setError('Error: ' + error.response.data.message);
                });
        }
    }

    return (
        <Modal className={classes.modal} open={(model != null)} onClose={onClose}>
            <div className={classes.paper}>
                <h2 id="transition-modal-title">{mode} Member</h2>
                <Formik initialValues={model}
                    enableReinitialize={true}
                    validationSchema={Yup.object({
                        buildingNo: Yup.string(),
                        wing: Yup.string(),
                        unit: Yup.string().required('Required'),
                        nonFlat: Yup.boolean().required('Required'),
                        area: Yup.number(),
                        areaSqmt: Yup.number()
                    })}
                    validateOnMount={true}
                    onSubmit={onSubmit}
                >
                    {({ isValid }) => (
                        <Form autoComplete="off" onKeyDown={(e) => { if (e.keyCode === 13 && e.target.id !== 'save') e.preventDefault(); }}>
                            <div>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="buildingNo"
                                                name="buildingNo"
                                                label="Building No"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('wing').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="wing"
                                                name="wing"
                                                label="Wing"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('unit').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="unit"
                                                name="unit"
                                                label="Unit"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('nonFlat').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpSelectField
                                                id="nonFlat"
                                                name="nonFlat"
                                                label="Shop"
                                                fullWidth
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('area').focus(); } }}
                                            >
                                                <MenuItem value={true}>Yes</MenuItem>
                                                <MenuItem value={false}>No</MenuItem>
                                            </SharpSelectField>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="area"
                                                name="area"
                                                type="number"
                                                label="Area (Sqft)"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('areasqmt').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="areaSqmt"
                                                name="areaSqmt"
                                                type="number"
                                                label="Area (Sqmt)"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('save').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="parking2"
                                                name="parking2"
                                                type="number"
                                                label="Parking (2W)"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('save').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControl className={classes.formControlFullWidth}>
                                            <SharpTextField
                                                id="parking4"
                                                name="parking4"
                                                type="number"
                                                label="Parking (4W)"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('save').focus(); } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                {error}
                            </div>
                            <div>
                                <Button id="save" variant="contained" color="primary" type="submit" disabled={!isValid}>
                                    Save
                            </Button>
                                <Button variant="contained" color="secondary" type="submit" onClick={() => { setError(''); onClose(); }}>
                                    Cancel
                            </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal >
    );
}

export default connect(mapToProps, actions)(MemberEdit);