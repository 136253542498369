import React, { useState } from 'react';
import { Button, CssBaseline, TextField, Typography, Container, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    error: {
        color: 'red'
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        marginBottom: 10
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    cityscape: {
        zIndex: -1,
        opacity: 0.4,
        position: 'fixed',
        left: 0,
        bottom: 0,
        height: 250,
        width: '1000%',
        backgroundImage: 'url(/images/cityscape.png)',
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'bottom left',
        animation: 'slide 90s linear infinite'
    }
}));

function Activate() {
    const classes = useStyles();

    const [step, setStep] = useState(1);
    const [model, setModel] = useState({ emailOrMobile: '' });
    const [model2, setModel2] = useState({ emailOrMobile: '', token: '', password: '' });
    const [error, setError] = useState('');

    const history = useHistory();

    function next() {
        axios({
            method: 'POST',
            url: '/user/sendtoken',
            data: JSON.stringify(model)
        })
            .then(response => {
                setError('');
                setStep(2);
            })
            .catch(error => {
                setError('Email or mobile does not exist');
            });
    }

    function setPassword() {
        axios({
            method: 'POST',
            url: '/user/activate',
            data: JSON.stringify(model2)
        })
            .then(response => {
                setError('');
                history.push('/');
            })
            .catch(error => {
                setError('Activation failed');
            });
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img className={classes.logo} src="images/sharp_logo3.svg" alt="Sharp Logo" />
                <Typography component="h1" variant="h5">Activate your Account</Typography>
                {
                    step === 1 && <div className={classes.form}>
                        <TextField variant="outlined" margin="normal" required fullWidth id="emailOrMobile"
                            label="Email or Mobile" name="emailOrMobile" autoFocus
                            onChange={(e) => {
                                setModel({ ...model, emailOrMobile: e.target.value });
                                setModel2({ ...model2, emailOrMobile: e.target.value });
                            }}
                            onKeyUp={(e) => { if (e.keyCode === 13) { next() } }} />
                        <div className={classes.error}>
                            {error}
                        </div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={next}
                        >
                            Next
                    </Button>
                    </div>
                }
                {
                    step === 2 && <div className={classes.form}>
                        <p>A 6-digit code has been emailed to you. Please enter it below and set your password.</p>
                        <p>Note: Sometimes the email goes in your Spam folder. So please check this folder in your email client)</p>
                        <TextField variant="outlined" margin="normal" required fullWidth id="token"
                            label="Code" name="token" autoFocus
                            onChange={(e) => setModel2({ ...model2, token: e.target.value })} />
                        <TextField variant="outlined" margin="normal" required fullWidth id="password" type="password"
                            label="Enter New Password" name="password"
                            onChange={(e) => setModel2({ ...model2, password: e.target.value })} />
                        <div className={classes.error}>
                            {error}
                        </div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={setPassword}
                        >
                            Activate
                    </Button>
                    </div>
                }
            </div>
        </Container>
    );
}

export default Activate;