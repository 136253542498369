import createStore from 'redux-zero';

let isAuthenticated = false;

let accessToken = localStorage.getItem('accessToken');
if(accessToken) isAuthenticated = true;

const initialState = {
    isAuthenticated: isAuthenticated,
    selectedSociety: parseInt(localStorage.getItem('selectedSociety')),
    selectedSocietyName: localStorage.getItem('selectedSocietyName'),
    currentPeriod: JSON.parse(localStorage.getItem('currentPeriod')),
    societyDetailsFormType: 'Edit'  // Edit or Create
};

if (isNaN(initialState.selectedSociety)) initialState.selectedSociety = null;

const store = createStore(initialState);

export default store;
