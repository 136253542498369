import React from 'react';
import { useField } from 'formik';
import { TextField } from '@material-ui/core';

function SharpTextField({ ...props }) {
    const [field, meta] = useField(props);

    return (
        <TextField {...field} {...props} helperText={meta.touched ? meta.error : ""} error={meta.touched ? Boolean(meta.error) : false} />
    );
};

export default SharpTextField;