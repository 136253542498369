import React from 'react';
import { useField } from 'formik';
import { Select, FormControl, InputLabel, FormHelperText } from '@material-ui/core';

function SharpSelectField({ ...props }) {
    const [field, meta] = useField(props);

    return (
        <FormControl error={meta.touched ? Boolean(meta.error): false}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                {props.label}
            </InputLabel>
            <Select {...field} {...props} error={meta.touched ? Boolean(meta.error) : false}>
                {props.children}
            </Select>
            <FormHelperText>{meta.touched ? meta.error : ""}</FormHelperText>
        </FormControl>
    );
};

export default SharpSelectField;