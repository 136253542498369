import React, { useState, useEffect } from 'react';
import { Typography, Paper, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import moment from 'moment';
import BusinessUserEdit from './BusinessUserEdit';
import ConfirmDialog from '../ConfirmDialog';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginBottom: 10
    },
    actionButton: {
        cursor: 'pointer',
        marginRight: 3
    },
    resendButton: {
        marginLeft: 5
    }
}));

function BusinessUsers() {
    const classes = useStyles();

    const [refreshData, setRefreshData] = React.useState(1);

    const [users, setUsers] = useState([]);
    const [editMode, setEditMode] = React.useState(null);
    const [editModel, setEditModel] = React.useState();

    const [deleteModel, setDeleteModel] = React.useState();
    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

    let { id } = useParams();
    let businessId = 0;

    if (window.decodedJwt.userType === 'Business') {
        businessId = JSON.parse(window.decodedJwt.businesses)[0];
    } else {
        businessId = parseInt(id);
    }

    useEffect(() => {
        axios.get(`/user/listbybusiness/${businessId}`)
            .then(response => {
                setUsers(response.data);
            });
    }, [refreshData, businessId]);

    function create() {
        setEditMode('Create');
        setEditModel({
            id: 0, email: '', mobile: '', firstName: '', middleName: '', lastName: '',
            role: 'Administrator', status: 'Invited', businessId: businessId
        });
    }

    function closeEditModal() {
        setEditModel(null);
        setRefreshData(refreshData + 1);
    }

    function edit(row) {
        row.businessId = businessId;
        setEditMode('Edit');
        setEditModel(row);
    }

    function ddelete(row) {
        setDeleteModel(row);
        setShowConfirmDelete(true);
    }

    function closeConfirmDialog() {
        setShowConfirmDelete(false);
    }

    function deleteBusiness(row) {
        closeConfirmDialog();
        axios({
            method: 'DELETE',
            url: '/user/' + row.id
        })
            .then(response => {
                setRefreshData(refreshData + 1);
            });
    }

    function resendInviteEmail(row) {
        if (window.confirm('Are you sure you want to resend invitation email?')) {
            axios({
                method: 'POST',
                url: '/user/resendinvite/' + row.id
            })
                .then(response => {
                });
        }
    }

    return (
        <>
            <Typography variant="h5">Users</Typography>
            <div>
                {window.decodedJwt.role === 'Administrator' &&
                    <Button className={classes.topBar} variant="contained" color="primary" size="small" onClick={create}>Create User</Button>
                }
            </div>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>First Name</TableCell>
                            <TableCell>Middle Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Last Login</TableCell>
                            <TableCell>Failed Logins</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(row => (
                            <TableRow hover key={row.id}>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>{row.mobile}</TableCell>
                                <TableCell>{row.firstName}</TableCell>
                                <TableCell>{row.middleName}</TableCell>
                                <TableCell>{row.lastName}</TableCell>
                                <TableCell>{row.role}</TableCell>
                                <TableCell>
                                    {row.status}
                                    {row.status === 'Invited' &&
                                        <Button
                                            className={classes.resendButton}
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => resendInviteEmail(row)}>Resend Email
                                        </Button>}
                                </TableCell>
                                <TableCell>{row.lastLogin ? moment(row.lastLogin).format('DD/MM/YYYY HH:mm:ss') : 'Never'}</TableCell>
                                <TableCell>{row.failedAttempts}</TableCell>
                                <TableCell>
                                    {window.decodedJwt.role === 'Administrator' && <>
                                        <EditIcon className={classes.actionButton} onClick={() => edit(row)} />
                                        <DeleteIcon className={classes.actionButton} onClick={() => ddelete(row)} />
                                    </>}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <BusinessUserEdit mode={editMode} model={editModel} onClose={closeEditModal} />
            <ConfirmDialog model={deleteModel}
                show={showConfirmDelete}
                title="Confirm Delete"
                message="Are you sure you want to delete this user?"
                onClose={closeConfirmDialog}
                onYes={deleteBusiness} />
        </>
    );
}

export default BusinessUsers;