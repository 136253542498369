import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Typography, TextField, Button, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { connect } from 'redux-zero/react';
import actions from '../../actions';
import MemberEdit from './MemberEdit';
import ResidentEdit from './ResidentEdit';
import ConfirmDialog from '../ConfirmDialog';

const useStyles = makeStyles(theme => ({
    topBar: {
        marginBottom: 10
    },
    actionButton: {
        cursor: 'pointer'
    },
    actionsColumnRight: {
        width: 60
    },
    actionsColumn: {
        width: 110
    },
    residents: {
        width: '100%'
    },
    residentName: {
    },
    residentEmail: {
        width: 200
    },
    residentPhone: {
        width: 200
    },
    residentMobile: {
        width: 200
    },
    primaryResident: {
        fontWeight: 'bold'
    }
}));

const mapToProps = ({ selectedSociety }) => ({ selectedSociety });

function Members({ selectedSociety }) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const newMemberEmptyValue = {
        code: '',
        codeError: false,
        buildingNo: '',
        buildingNoError: false,
        wing: '',
        wingError: false,
        unit: '',
        unitError: false,
        area: '',
        areaError: false,
        areaSqmt: '',
        areaSqmtError: false,
        parking2: '',
        parking2Error: false,
        parking4: '',
        parking4Error: false,
        title: '',
        titleError: false,
        firstName: '',
        firstNameError: false,
        middleName: '',
        middleNameError: false,
        lastName: '',
        lastNameError: false,
        email: '',
        emailError: false,
        phone: '',
        phoneError: false,
        mobile: '',
        mobileError: false
    };

    const [refreshData, setRefreshData] = React.useState(1);
    const [members, setMembers] = useState([]);
    const [newMember, setNewMember] = useState(newMemberEmptyValue);

    const [editMode, setEditMode] = React.useState(null);
    const [editUnitMode, setEditUnitMode] = React.useState(null);

    const [editModel, setEditModel] = React.useState();
    const [editUnitModel, setEditUnitModel] = React.useState();

    const [deleteModel, setDeleteModel] = React.useState();
    const [deleteUnitModel, setDeleteUnitModel] = React.useState();

    const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
    const [showUnitConfirmDelete, setShowUnitConfirmDelete] = React.useState(false);

    useEffect(() => {
        axios.get('/member/listwithresidents', { headers: { 'X-SocietyId': selectedSociety } })
            .then(response => {
                setMembers(response.data);

                var maxAccountCode = Math.max.apply(Math, response.data.map(function (o) { return o.code; }));
                if (maxAccountCode === -Infinity) maxAccountCode = 2610000;
                setNewMember({ ...newMember, code: maxAccountCode + 1 });
                let aBuildingNo = document.getElementById("abuildingNo");
                if(aBuildingNo) aBuildingNo.focus();
            });
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedSociety, refreshData]);

    function addNewMember() {
        let error = false;

        newMember.codeError = false;
        if (!newMember.code || (newMember.code < 2610000 || newMember.code > 2619999)) {
            newMember.codeError = true;
            error = true;
        }

        newMember.unitError = false;
        if (!newMember.unit) {
            newMember.unitError = true;
            error = true;
        }

        newMember.firstNameError = false;
        if (!newMember.firstName) {
            newMember.firstNameError = true;
            error = true;
        }

        newMember.lastNameError = false;
        if (!newMember.lastName) {
            newMember.lastNameError = true;
            error = true;
        }

        setNewMember({ ...newMember });

        const value = Object.assign({}, newMember);

        if (newMember.buildingNo === '') {
            value.buildingNo = null;
        }

        if (newMember.wing === '') {
            value.wing = null;
        }

        if (newMember.unit === '') {
            value.unit = null;
        }

        if (newMember.area === '') {
            value.area = null;
        }

        if (newMember.areaSqmt === '') {
            value.areaSqmt = null;
        }

        if (newMember.parking2 === '') {
            value.parking2 = 0;
        }

        if (newMember.parking4 === '') {
            value.parking4 = 0;
        }

        if (newMember.title === '') {
            value.title = null;
        }

        if (newMember.middleName === '') {
            value.middleName = null;
        }

        if (newMember.email === '') {
            value.email = null;
        }

        if (newMember.mobile === '') {
            value.mobile = null;
        }

        if (!error) {
            axios({
                method: 'POST',
                url: '/member',
                headers: { 'X-SocietyId': selectedSociety },
                data: JSON.stringify(value)
            })
                .then(response => {
                    setNewMember(newMemberEmptyValue);
                    setRefreshData(refreshData + 1);
                    enqueueSnackbar('Saved', { variant: 'success' });
                })
                .catch(error => {
                    alert(error.response.data.message);
                });
        }
    }

    function add(row) {
        setEditMode('Create');
        setEditModel({ id: 0, code: row.code, type: 'Member', title: '', firstName: '', middleName: '', lastName: '', email: '', phone: '', mobile: '' });
    }

    function edit(row) {
        const value = Object.assign({}, row);

        if (value.title === null) {
            value.title = '';
        }

        if (value.middleName === null) {
            value.middleName = '';
        }

        if (value.email === null) {
            value.email = '';
        }

        if (value.phone === null) {
            value.phone = '';
        }

        if (value.mobile === null) {
            value.mobile = '';
        }

        setEditMode('Edit');
        setEditModel(value);
    }

    function closeEditModal() {
        setEditModel(null);
        setRefreshData(refreshData + 1);
    }

    function closeConfirmDialog() {
        setShowConfirmDelete(false);
    }

    function ddelete(row) {
        setDeleteModel(row);
        setShowConfirmDelete(true);
    }

    function deleteResident(row) {
        closeConfirmDialog();
        axios({
            method: 'DELETE',
            url: '/resident/' + row.id,
            headers: { 'X-SocietyId': selectedSociety }
        })
            .then(response => {
                setRefreshData(refreshData + 1);
            });
    }

    function closeUnitEditModal() {
        setEditUnitModel(null);
        setRefreshData(refreshData + 1);
    }

    function editUnit(row) {
        const value = Object.assign({}, row);

        if (value.buildingNo === null) {
            value.buildingNo = '';
        }

        if (value.wing === null) {
            value.wing = '';
        }

        if (value.unit === null) {
            value.unit = '';
        }

        if (value.area === null) {
            value.area = '';
        }

        if (value.areaSqmt === null) {
            value.areaSqmt = '';
        }

        if (value.parking2 === null) {
            value.parking2 = '';
        }

        if (value.parking4 === null) {
            value.parking4 = '';
        }

        setEditUnitMode('Edit');
        setEditUnitModel(value);
    }

    function deleteUnit(row) {
        setDeleteUnitModel(row);
        setShowUnitConfirmDelete(true);
    }

    function closeUnitConfirmDialog() {
        setShowUnitConfirmDelete(false);
    }

    function deleteAccount(row) {
        closeUnitConfirmDialog();
        axios({
            method: 'DELETE',
            url: '/member/' + row.id,
            headers: { 'X-SocietyId': selectedSociety }
        })
            .then(response => {
                setRefreshData(refreshData + 1);
            });
    }

    function importFromExcel(e) {
        const formData = new FormData();
        formData.append('file', e.target.files[0])
        e.target.value = null;

        axios({
            method: 'POST',
            url: '/member/importfromexcel',
            headers: { 'Content-Type': 'multipart/form-data', 'X-SocietyId': selectedSociety },
            data: formData
        })
            .then(response => {
                enqueueSnackbar('Import complete', { variant: 'success' });
                setRefreshData(refreshData + 1);
            })
            .catch(error => {
                enqueueSnackbar('Import failed', { variant: 'danger' });
                alert(error.response.data.message);
            });
    }

    return (
        <>
            <Typography variant="h5">Members</Typography>
            <div className={classes.topBar}>
                <form style={{ float: 'left', marginRight: 10, marginBottom: 5 }}>
                    <input
                        accept=".xlsx"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={importFromExcel}
                    />
                    {window.decodedJwt.role === 'Administrator' &&
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" size="small" component="span">Import from Excel</Button>
                        </label>
                    }
                </form>
                {window.decodedJwt.role === 'Administrator' &&
                    <a href="/templates/Member_Import_Template.xlsx">Download Import Template</a>
                }
            </div>
            <form autoComplete="off" onKeyDown={(e) => { if (e.keyCode === 13 && e.target.id !== 'aadd') e.preventDefault(); }}>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell>Bldg</TableCell>
                                <TableCell>Wing</TableCell>
                                <TableCell>Unit</TableCell>
                                <TableCell>Parking</TableCell>
                                <TableCell>Members/Residents</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {members.map((row, index) => (
                                <TableRow hover key={index}>
                                    <TableCell>
                                        {window.decodedJwt.role === 'Administrator' && <>
                                            <EditIcon className={classes.actionButton} onClick={() => editUnit(row)} />
                                            <DeleteIcon className={classes.actionButton} onClick={() => deleteUnit(row)} />
                                        </>}
                                    </TableCell>
                                    <TableCell>{row.code}</TableCell>
                                    <TableCell>{row.buildingNo}</TableCell>
                                    <TableCell>{row.wing}</TableCell>
                                    <TableCell>{row.unit}{row.nonFlat ? ' (Shop)' : ''}</TableCell>
                                    <TableCell>2W: {row.parking2} 4W: {row.parking4}</TableCell>
                                    <TableCell>
                                        <table className={classes.residents}>
                                            <tbody>
                                                {row.residents.map((r, i) => (
                                                    <tr key={i}>
                                                        <td className={classes.residentName}>{r.title} {r.firstName} {r.middleName} {r.lastName} ({r.type})</td>
                                                        <td className={classes.actionsColumnRight}>
                                                            {window.decodedJwt.role === 'Administrator' && <>
                                                                <EditIcon className={classes.actionButton} onClick={() => edit(r)} />
                                                                {row.residents.length > 1 &&
                                                                    <DeleteIcon className={classes.actionButton} onClick={() => ddelete(r)} />
                                                                }
                                                            </>}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </TableCell>
                                    <TableCell>
                                        {window.decodedJwt.role === 'Administrator' &&
                                            <AddIcon className={classes.actionButton} onClick={() => add(row)} />
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                            {window.decodedJwt.role === 'Administrator' && <TableRow>
                                <TableCell colSpan="8">
                                    <table>
                                        <tbody>
                                            <tr key={'add'}>
                                                <td>{newMember.code}</td>
                                                <td>
                                                    <TextField id="abuildingNo" value={newMember.buildingNo} error={newMember.buildingNoError} placeholder="Building"
                                                        onChange={(e) => setNewMember({ ...newMember, buildingNo: e.target.value })}
                                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('awing').focus(); } }} />
                                                </td>
                                                <td>
                                                    <TextField id="awing" value={newMember.wing} error={newMember.wingError} placeholder="Wing"
                                                        onChange={(e) => setNewMember({ ...newMember, wing: e.target.value })}
                                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('aunit').focus(); } }} />
                                                </td>
                                                <td>
                                                    <TextField id="aunit" value={newMember.unit} error={newMember.unitError} placeholder="Unit"
                                                        onChange={(e) => setNewMember({ ...newMember, unit: e.target.value })}
                                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('aarea').focus(); } }} />
                                                </td>
                                                <td>
                                                    <TextField id="aarea" type="number" value={newMember.area} error={newMember.areaError} placeholder="Area"
                                                        onChange={(e) => setNewMember({ ...newMember, area: e.target.value ? parseFloat(e.target.value) : null })}
                                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('atitle').focus(); } }} />
                                                </td>
                                                <td>
                                                    <TextField id="atitle" value={newMember.title} error={newMember.titleError} placeholder="Title"
                                                        onChange={(e) => setNewMember({ ...newMember, title: e.target.value })}
                                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('afirstName').focus(); } }} />
                                                </td>
                                                <td>
                                                    <TextField id="afirstName" value={newMember.firstName} error={newMember.firstNameError} placeholder="First Name"
                                                        onChange={(e) => setNewMember({ ...newMember, firstName: e.target.value })}
                                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('amiddleName').focus(); } }} />
                                                </td>
                                                <td>
                                                    <TextField id="amiddleName" value={newMember.middleName} error={newMember.middleNameError} placeholder="Mid Name"
                                                        onChange={(e) => setNewMember({ ...newMember, middleName: e.target.value })}
                                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('alastName').focus(); } }} />
                                                </td>
                                                <td>
                                                    <TextField id="alastName" value={newMember.lastName} error={newMember.lastNameError} placeholder="Last Name"
                                                        onChange={(e) => setNewMember({ ...newMember, lastName: e.target.value })}
                                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('aemail').focus(); } }} />
                                                </td>
                                                <td>
                                                    <TextField id="aemail" value={newMember.email} error={newMember.emailError} placeholder="Email"
                                                        onChange={(e) => setNewMember({ ...newMember, email: e.target.value })}
                                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('aphone').focus(); } }} />
                                                </td>
                                                <td>
                                                    <TextField id="aphone" value={newMember.phone} error={newMember.phoneError} placeholder="Phone"
                                                        onChange={(e) => setNewMember({ ...newMember, phone: e.target.value })}
                                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('amobile').focus(); } }} />
                                                </td>
                                                <td>
                                                    <TextField id="amobile" value={newMember.mobile} error={newMember.mobileError} placeholder="Mobile"
                                                        onChange={(e) => setNewMember({ ...newMember, mobile: e.target.value })}
                                                        onKeyUp={(e) => { if (e.keyCode === 13) { document.getElementById('aadd').focus(); } }} />
                                                </td>
                                                <td>
                                                    <Button id="aadd" variant="contained" size="small" color="primary" onClick={addNewMember}>Add</Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </TableCell>
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </form>

            <ResidentEdit mode={editMode} model={editModel} onClose={closeEditModal} />
            <ConfirmDialog model={deleteModel}
                show={showConfirmDelete}
                title="Confirm Delete"
                message="Are you sure you want to delete this member/resident?"
                onClose={closeConfirmDialog}
                onYes={deleteResident} />

            <MemberEdit mode={editUnitMode} model={editUnitModel} onClose={closeUnitEditModal} />
            <ConfirmDialog model={deleteUnitModel}
                show={showUnitConfirmDelete}
                title="Confirm Delete"
                message="Are you sure you want to delete this member?"
                onClose={closeUnitConfirmDialog}
                onYes={deleteAccount} />
        </>
    );
}

export default connect(mapToProps, actions)(Members);