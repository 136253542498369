import React from 'react';
import { useField } from 'formik';
import { Select, MenuItem, InputLabel } from '@material-ui/core';

function SharpBillingCycle({ ...props }) {
    const [field, meta] = useField(props);

    return (
        <>
            <InputLabel shrink>{props.label ? props.label : ""}</InputLabel>
            <Select {...field} {...props} error={meta.touched ? Boolean(meta.error) : false}>
                <MenuItem value="Monthly">Monthly</MenuItem>
                <MenuItem value="Quarterly">Quarterly</MenuItem>
                <MenuItem value="HalfYearly">Half-yearly</MenuItem>
                <MenuItem value="Yearly">Yearly</MenuItem>
            </Select>
        </>
    );
};

export default SharpBillingCycle;