import React from 'react';
import { CssBaseline, Link, Box, Typography, Container, makeStyles } from '@material-ui/core';
import { connect } from 'redux-zero/react';
import actions from '../actions';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sharpsociety.in">
        Sharp Accounting Services
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  error: {
    color: 'red'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    marginBottom: 10
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cityscape: {
    zIndex: -1,
    opacity: 0.4,
    position: 'fixed',
    left: 0,
    bottom: 0,
    height: 250,
    width: '1000%',
    backgroundImage: 'url(/images/cityscape.png)',
    backgroundRepeat: 'repeat-x',
    backgroundPosition: 'bottom left',
    animation: 'slide 90s linear infinite'
  }
}));

const mapToProps = ({ isAuthenticated }) => ({ isAuthenticated });

function SignIn({ setIsAuthenticated }) {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img className={classes.logo} src="images/sharp_logo3.svg" alt="Sharp Logo" />
        <Typography component="h1" variant="h5">
          Registration Complete
        </Typography>
        <p>Thank you for registering! We will contact you shortly.</p>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <div className={classes.cityscape}>
      </div>
    </Container>
  );
}

export default connect(mapToProps, actions)(SignIn);